/**
 * Favorite type
 * */
export enum EntityType {
  Poster = 'PrintlerFavorites',
  Photographer = 'PrintlerFavoritesPhotographer',
}

export enum ApiEndpoints {
  Favourite = 'photos/favorite',
  Favourites = 'photos/favorites',
  Photos = 'photos',
  RenderFavourites = 'general/favorites',
  RenderPostersInNav = 'photos/featuredbyids',
  ByOrderHistory = 'byorderhistory',
  ByMember = 'bymember',
  ByIds = 'byids',
  ByFeaturedProperty = 'featuredbyproperty',
  ByCompositionPageReference = 'bycompositionpagereference',
  ByFeaturedIdsFromDb = 'byfeaturedidsfromdb',
  CompositionPageList = 'compositionpagelist',
  StaffPicks = 'staffpicks',
  Dictionary = 'dictionary',
  Cart = 'shop/cart',
  CartItem = 'shop/cartitem',
  CartAddonItem = 'shop/cartaddonitem',
  CartClear = 'shop/cartclear',
  CartItems = 'shop/cartitems',
  EstimatedCartPrice = 'shop/getestimatedprice',
  ProductSelector = 'photos/productselector',
  ProductAddonsAndOptions = 'photos/addonsandoptions',
  ProductAddons = 'photos/addons',
  NewsletterForm = 'general/newsletterform',
  MemberPhotoUpload = 'memberphotos/upload',
  MemberPhotoDelete = 'memberphotos/revert',
  MemberPhotoUploadMedia = 'memberphotos/uploadmedia',
  ProductSelectorSimple = 'photos/productselectorsimple',
  GalleryWall = 'general/gallerywall',
  Contact = 'general/contact',
  WallPlanner = 'wallplanner', // deprecated/removed
  InstagramThumbnail = 'general/getinstagrampostthumbnail',
  FacebookConversionApiEndpoint = 'facebookconversionapi/push',
  // Voyado
  VoyadoAddContact = 'general/addvoyadocontact',
  VoyadoGetContact = 'general/getvoyadocontact',
  // Payouts
  Member = 'member',
  InitZimplerPayoutFlow = 'initzimplerpayout', // deprecated/removed
  // AI
  BySimilar = 'bysimilar',
  BySearch = 'searchaiasync',
}

/**
 * Viewport breakpoints
 * */
export enum Breakpoints {
  Small = 576,
  Medium = 768,
  Large = 992,
  XLarge = 1440,
  XXLarge = 1920,
}

/**
 * Addon css class 
 * */
export enum Addons {
  Margin = 'has-margin',
  Frame = 'm-frame',
  BlackFrame = 'm-frame--black',
  WhiteFrame = 'm-frame--white',
  OakFrame = 'm-frame--oak',
  WalnutFrame = 'm-frame--walnut',
  RokokoFrame = 'm-frame--rokoko',
  Passpartout = 'has-ppt',
  IsFineArt = 'is-fine-art'
}

export enum FileType {
  Js = 'js',
  Css = 'css',
}

export enum ProductType {
  Poster = 'Poster',
  FramedArtPrint = 'FramedArtPrint',
  FineArt = 'FineArt',
  Canvas = 'Canvas',
}

export enum AddonType {
  BlackFrame = 'BlackFrame',
  WhiteFrame = 'WhiteFrame',
  OakFrame = 'OakFrame',
  WalnutFrame = 'WalnutFrame',
  RokokoFrame = 'RokokoFrame',
  Passepartout = 'Passepartout',
}

export enum Margin {
  WithMargin = 'WithMargin',
  WithoutMargin = 'WithoutMargin',
}
