import Utilities from './utilities';

/**
 * Location redirect modal logic
 */
class LocationRedirectModal {
  static PopupCookieKey = 'PrintlerLocationModalDismissed';
  static LocationModalElement: HTMLElement = document.getElementById('locationModal');
  /**
   * Init
   */
  static init(): void {
    if (!LocationRedirectModal.LocationModalElement) {
      return;
    }

    // auto display if exists
     this.setupAutomaticDisplay();
  }

  /**
   * Display modal automatically on page load if not already displayed
  */
  static setupAutomaticDisplay(): void {
    const dismissModalButtons = LocationRedirectModal.LocationModalElement?.querySelectorAll('.js-close');

    // if cookie not exists or if cookie value differ from cacheKey - show popup
    const popupCookie = Utilities.getCookie(LocationRedirectModal.PopupCookieKey);
    const cacheKey = LocationRedirectModal.LocationModalElement.getAttribute('data-root');

    if (!popupCookie || popupCookie != cacheKey) {
      setTimeout(() => {
        const triggerModalButton: HTMLButtonElement = document.querySelector('#locationModalTrigger');
        triggerModalButton.click();
      }, 1500)
    }

    // set cookie on close
    dismissModalButtons.forEach(close => {
      close.addEventListener('click', () => {
        this.handleLocationModalDismissClick(cacheKey);
      }, true);
    });
  }

  /**
   * Handle modal dismiss button click
   * @param cacheKey - modal last update date
   */
  static handleLocationModalDismissClick(cacheKey: string): void {
    const event = new Event('hide.bs.modal');

    // listen for the close event
    document.addEventListener('hide.bs.modal', () => {
      Utilities.setCookie(LocationRedirectModal.PopupCookieKey, cacheKey, 1);
    }, false);

    // dispatch the event
    document.dispatchEvent(event);
  }
}

export default LocationRedirectModal;