/**
 * Initializes Timer logic
 */
class Timer {
  static TimerElements: NodeListOf<HTMLElement> = document.querySelectorAll('.js-timer');

  static init(): void {

    if (!Timer.TimerElements) {
      return;
    }

    this.TimerElements.forEach((timerElement: HTMLElement) => {
      if (timerElement.hasAttribute('data-countdown-date')) {
        const dateTimeString = timerElement.getAttribute('data-countdown-date');
        const countDownDate = new Date(dateTimeString.replace(/ /g, 'T')); // replace needed for Safari to parse the date correct
        const now = new Date();

        if (countDownDate > now) {
          Timer.countDownDate(countDownDate, timerElement);
        }
      }
    });
  }

  /**
   * Count down to specific date time
   * @param countDownDate- countdown date
   */
  static countDownDate(countDownDate: Date, timer: HTMLElement): void {
    // Update the count down every 1 second
    const x = setInterval(() => {

      // get today's date and time
      const now = new Date().getTime();

      // find the distance between now and the count down date
      const distance = countDownDate.getTime() - now;

      // time calculations for days, hours, minutes and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Display the result in the element with id="demo"
      timer.innerHTML = days + 'd ' + hours + 'h '
        + minutes + 'm ' + seconds + 's ';

      // if the count down is finished, remove timer
      if (distance < 0) {
        clearInterval(x);
        timer.remove();
      }
    }, 1000);

  }
}

export default Timer;
