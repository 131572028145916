import Utilities from './utilities';

/**
 * Copy to Clipboard logic
 * Text to copy is stored in button data attribute
 */
class Clipboard {

  /**
   * Init
   */
  static init(): void {
    const copyToClipboardButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.js-copy-clipboard');

    if (!copyToClipboardButtons) {
      return;
    }

    // loop and add click event
    copyToClipboardButtons.forEach((copyButton: HTMLElement) => {
      const copyToClipboardData = copyButton?.getAttribute('data-copy-text');

      copyButton.addEventListener('click', (e: Event) => {
        e.preventDefault();

        this.handleCopyClick(copyToClipboardData, copyButton);
      }, true);
    });
  }

  /**
   * Handle copy to clipboard button click
   * @param text - the text to copy to clipboard
   */
  static handleCopyClick(text: string, btn: HTMLElement): void {
    const confirmCopySuccessElement: HTMLElement = btn.parentElement.querySelector('.js-copy-confirm');

    if (!navigator.clipboard) {
      Clipboard.handleCopyClickFallback(text, confirmCopySuccessElement);
      return;
    }
    navigator.clipboard.writeText(text).then(() => {
      if (confirmCopySuccessElement) {
        confirmCopySuccessElement.style.opacity = '1';

        setTimeout(() => { confirmCopySuccessElement.style.opacity = '0'; }, 3000);
      }

      // dispatch custom event when copy completed
      const evt = document.createEvent('CustomEvent');
      evt.initCustomEvent('copied', true, false, { done: true });

      btn.dispatchEvent(evt);

      Utilities.log('Copying to clipboard was successful!');
    }, function (err) {
      Utilities.error('Could not copy text: ' + err);
    });
  }

  /**
  * Handle copy to clipboard button click for older browsers
  * @param text - the text to copy to clipboard
  */
  static handleCopyClickFallback(text: string, confirmCopySuccessElement: HTMLElement): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';

      Utilities.log('Copying text command was ' + msg);

      if (successful && confirmCopySuccessElement) {
        confirmCopySuccessElement.style.opacity = '1';//.classList.remove('d-none');

        setTimeout(() => { confirmCopySuccessElement.style.opacity = '0'; }, 3000);
      }

    }
    catch (err) {
      Utilities.error('Oops, unable to copy: ' + err);
    }
  }
}

export default Clipboard;