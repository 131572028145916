
class Modal {
  static init(): void {
    const modals = document.querySelectorAll('.modal');
    modals.forEach((element) => {
      if (element.getAttribute('data-modal-static')) {
      /* eslint-disable */
        (element as any).Modal?.show();
      /* eslint-enable */
      }
    })
  }
}

export default Modal;