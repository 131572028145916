import VoyadoService from './voyado-service';
import { VoyadoContact } from '../models';
import Utilities from './utilities';

/**
 * Initializes account logic
 */
class Account {
  static PaypalRegisterButton: HTMLElement = document.querySelector('.js-paypal-register-button');
  static FormFieldset: HTMLElement = document.querySelector('.js-form-fieldset');
  static ConfirmRegisterTerms: HTMLElement = document.querySelector('.js-confirm-terms');
  static FormButton: HTMLElement = document.querySelector('.js-form-btn');
  static RegisterForm: HTMLElement = document.querySelector('.js-register-form');
  static BtnDisabledCssClass = 'a-btn--disabled';

  /**
   * Init
   */
  static init(): void {
    if (this.PaypalRegisterButton != null) {
      this.PaypalRegisterButton.addEventListener('click', this.paypalRegisterClick, true);
    }

    if (this.ConfirmRegisterTerms != null) {
      this.ConfirmRegisterTerms.addEventListener('change', this.confirmTermsChange, true);
    }

    if (this.RegisterForm != null) {
      this.RegisterForm.addEventListener('submit', () => {
        this.FormButton?.querySelector('.js-form-btn-spinner').classList.remove('d-none');
        this.FormButton?.setAttribute('disabled', 'disabled');
        return false;
      }, true);
    }

    /**
      Artist Login Event
      - push datalayer event
      - set logged in artist as current contact in Voyado
    */
    window.addEventListener('ArtistLoginEvent', (e: CustomEvent) => {

      // google datalayer push
      if (window.Cookiebot?.consent.statistics) {
        window.dataLayer.push({
          'event': 'Artist Logged In',
          'eventCategory': 'Artist Logged In',
          'eventAction': `${e.detail.customData.memberId}`, //'@memberId',
          'customData': {
            'email': `${e.detail.customData.email}`,
            'country': `${e.detail.customData.country}`,
            'city': `${e.detail.customData.city}`,
            'phone': `${e.detail.customData.phone}`,
          }
        });
      }

      // if voaydo contact already set - return
      if (Utilities.getCookie('_val') != null) {
        return;
      }

      // set voyado contact if visitor given consent
      if (window.Cookiebot?.consent.statistics) {
        VoyadoService.getVoyadoContactAsync(e.detail.customData.email, 'Contact').then((contact: VoyadoContact) => {
          if (contact == null) {
            console.warn('Could not get customer id');
            return;
          }

          VoyadoService.setVoyadoContactId(contact.id);
        });
      }
    });
  }

  /**
   * Paypal register button click
   * @param event
   */
  static paypalRegisterClick(event: Event): boolean {
    const link = event.srcElement as HTMLInputElement;

    if (link.classList.contains(Account.BtnDisabledCssClass)) {
      event.preventDefault;
      return false;
    }

    return true;
  }

  /**
   * Confirm register terms checkbox
   * @param event
   */
  static confirmTermsChange(event: Event): void {
    const checkboxElement = event.srcElement as HTMLInputElement;

    if (checkboxElement.checked) {
      Account.PaypalRegisterButton.classList.remove(Account.BtnDisabledCssClass);
      Account.FormFieldset.removeAttribute('disabled');
    }
    else {
      Account.PaypalRegisterButton.classList.add(Account.BtnDisabledCssClass);
      Account.FormFieldset.setAttribute('disabled', 'disabled');
    }
  }
}

export default Account;