import { Offset } from '../models';

class Utilities {
  /**
   * Log a message
   * @param msg - message to log
   */
  static log(msg: string): void {
    if (typeof window.console !== 'undefined') {
      console.log(msg);
    }
  }

  /**
   * Log an error message
   * @param msg The message to log
   */
  static error(msg: string): void {
    if (typeof window.console !== 'undefined') {
      console.error(msg);
    }
  }

  /**
   * Log an exception
   * @param e The exception to log
   * @param msg (optional) An optional message tol log
   */
  static exception(e: Error, msg?: string): void {
    if (typeof window.console !== 'undefined') {
      if (msg) {
        console.error(msg, e);
      } else {
        console.error(e);
      }
    }
  }

  /**
   * Print page
   * @param e - event
   */
  static printPage(e?: Event): void {
    if (e) {
      e.preventDefault();
    }

    window.print();
  }

  /**
   * Set cookie
   * @param name - name of cookie
   * @param value - cookie value
   * @param days - cookie lifetime
   */
  static setCookie(name: string, value: string, days: number): void {
    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    document.cookie = `${name}=${value};path=/;expires=${d.toUTCString()}`;
  }

  /**
   * Get cookie by name
   * @param name - cookie name
   */
  static getCookie(name: string): string {
    const cookieName = name;
    const re = new RegExp(`${cookieName}=([^;]+)`);
    const value = re.exec(document.cookie);

    return value != null ? unescape(value[1]) : null;
  }

  /**
   * Get Html Element offset
   * @param elm - element
   */
  static getOffset(elm: Element): Offset {
    const rect = elm.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
    };
  }

  /**
   * Setup smooth on page scroll links
   */
  static setupSmoothScrollLinks(): void {
    const smoothScrollElements: NodeListOf<HTMLElement> = document.querySelectorAll('.js-scroll-link');

    smoothScrollElements.forEach(scrollElm => {
      scrollElm.addEventListener('click', () => {
        Utilities.smoothScroll(scrollElm);
      });
    });
  }

  /**
   * Create a smooth scroll
   * @param elm - HTMLElement with data attribute containing target element id
   */
  static smoothScroll(elm: HTMLElement): void {
    const target: HTMLElement = document.querySelector(elm.dataset.href);
    const originalTop = this.distanceToTop(target);

    window.scrollBy({ top: originalTop, left: 0, behavior: 'smooth' });

    const checkIfDone = setInterval(function () {
      const atBottom = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;

      if (Utilities.distanceToTop(target) === 0 || atBottom) {
        target.tabIndex = -1;
        target.focus();
        window.history.pushState('', '', elm.dataset.href);
        clearInterval(checkIfDone);
      }
    }, 100);
  }

  /**
   * Returns the distance to top
   * @param elm - Element
   */
  static distanceToTop(elm: Element): number {
    return Math.floor(elm.getBoundingClientRect().top);
  }

  /**
   * Automatically scroll down to posters grid if in pagination
   */
  static setupPaginationAutoScroll(): void {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (!urlParams) {
      return;
    }

    const isPagination = urlParams.get('page');

    if (isPagination !== null) {
      const results: HTMLElement = document.querySelector('#searchResults');
      results?.scrollIntoView();
    }
  }
  /**
   * Check if given field exists and has the given value
   * @param field - the query parameter
   * @param fieldValue - the field value
   */
  static hasParameterWithValue(field: string, fieldValue: string): boolean {
    const urlParams = new URLSearchParams(window.location.search);
    const fieldParameter = urlParams.get(field);

    return fieldParameter !== null && fieldParameter === fieldValue;
  }

  /**
   * Get url parameter value by name
   *
   */
  static getParameterValue(field: string): string {
    const urlParams = new URLSearchParams(window.location.search);
    const fieldParameter = urlParams.get(field);

    return fieldParameter;
  }

  /**
   * Checks if small viewport width
   */
  static isMobileViewportSize(): boolean {
    return document.body.clientWidth < 768;
  }

  /**
   * Fetch all forms we want to apply custom Bootstrap validation styles to, and prevent submission if invalid
   */
  static validateForms(): void {
    const forms = document.getElementsByClassName('needs-validation');

    Array.prototype.filter.call(forms, function (form: HTMLFormElement) {
      form.addEventListener(
        'submit',
        function (event) {
          if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
          }
          form.classList.add('was-validated');
        },
        false,
      );
    });
  }

  /**
   * Generates a unique string of a given length.
   * The string will be composed of capital letters, small letters and numbers.
   * @param length (optional) The length of the string to generate, defaults to 32 if none is set
   * @returns The generated string
   */
  static generateUniqueString(length?: number): string {
    const validChars = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const l = typeof length === 'number' && length > 0 ? length : 32;
    let generated = '';

    for (let i = 0; i < l; i++) {
      const rand = Math.ceil(Math.random() * (validChars.length - 1));
      let char = validChars[rand];

      if (Math.random() < 0.5) {
        char = char.toUpperCase();
      }

      generated += char;
    }

    return generated;
  }

  /**
   * Check if desktop safari
   * @returns a bool indicating true or false
   */
  static isSafariDesktop(): boolean {
    const uA = navigator.userAgent;
    const vendor = navigator.vendor;

    if (/Safari/i.test(uA) && /Apple Computer/.test(vendor) && !/Mobi|Android/i.test(uA)) {
      return true;
    }

    return false;
  }

  /**
   * Hide preloader/spinner when image is loaded 
   */
  static initImagePreloaders(): void {
    // fetch all lazyloaded images with spinners/preloaders
    const preloaderImages: NodeListOf<HTMLElement> = document.querySelectorAll('.js-img-lazyload');

    preloaderImages.forEach((imageElement: HTMLElement) => {
      imageElement.addEventListener('load', () => {
        const selector = `.${imageElement.getAttribute('data-spinner')}`
        const imgSpinner = document.querySelector(selector);
        imgSpinner.classList.add('d-none');
      });
    });
  }

  /**
   * Adds a dark backdrop on modals
   * @param modal
   */
  static setupDarkModalBackdrop(modal: HTMLElement): void {
    const backDropClass = 'backdrop-dark';
    const bodyElement = document.querySelector('html > body');

    // add backdrop class
    modal.addEventListener('show.bs.modal', () => {
      bodyElement.classList.add(backDropClass);
    }, false);

    // remove backdrop color on close
    modal.addEventListener('hidden.bs.modal', () => {
      bodyElement.classList.remove(backDropClass);
    }, false);
  }

  /**
   * Check if element is in viewport
   * @param el
   */
  static isScrolledIntoView(el: HTMLElement): boolean {
    const rect = el.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;

    // Only completely visible elements return true:
    const isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
    // Partially visible elements return true:
    //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
  }

  /**
   * Debounces function call with supplied timeout
   * @param func - the function to run after timeout
   * @param timeout - number of milliseconds to debounce function call
   */
  static debounce<Params extends any[]>( // eslint-disable-line
    func: (...args: Params) => any, // eslint-disable-line
    timeout: number,
  ): (...args: Params) => void {
    let timer: NodeJS.Timeout
    return (...args: Params) => { // eslint-disable-line
      clearTimeout(timer)
      timer = setTimeout(() => {
        func(...args)
      }, timeout)
    }
  }

  /**
   * Add decimals to a number
   * @param num - an integer value
   * @param decimals - number of decimals
   */
  static addDecimal(num: number, decimals = 2): string {
    return (Math.round(num * 100) / 100).toFixed(decimals);
  }

  /**
   * Format price by currency
   */
  static formatPrice(price: number, currency: string, decimals = 2): string {
    // SEK/NOK/DKK - we use Swedish formatting for all of these currencies
    if (currency == 'SEK' || currency == 'NOK' || currency == 'DKK') {
      const sek = Intl.NumberFormat('sv-SE', {
        style: 'currency',
        currency: 'SEK',
        maximumFractionDigits: decimals,
        minimumFractionDigits: price % 1 == 0 ? 0 : 2, // if any decimal set a minimum of two decimals otherwize none.
        currencyDisplay: 'symbol',
      });

      return sek.format(price);
    }
    // DKK - this will use "." as a thousand separator and also append a "." after "kr"
    //if (currency == 'DKK') {
    //  const dkk = Intl.NumberFormat('da-DK', {
    //    style: 'currency',
    //    currency: 'DKK',
    //    maximumFractionDigits: decimals,
    //    minimumFractionDigits: 0
    //  });

    //  return dkk.format(price);
    //}

    // PLN - this will use "." as a thousand separator and also append a "." after "sl"
    if (currency == 'PLN') {
      const pln = Intl.NumberFormat('pl-PL', {
        style: 'currency',
        currency: 'PLN',
        maximumFractionDigits: decimals,
        minimumFractionDigits: 0
      });

      return pln.format(price);
    }

    // CZK
    if (currency == 'CZK') {
      const czk = Intl.NumberFormat('cs-CZ', {
        style: 'currency',
        currency: 'CZK',
        maximumFractionDigits: decimals,
        minimumFractionDigits: 0
      });

      return czk.format(price);
    }

    // GBP
    if (currency == 'GBP') {
      const gbp = Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        maximumFractionDigits: decimals,
        minimumFractionDigits: 2
      });

      return gbp.format(price);
    }

    /* Defaults to euro */

    // EUR
    // format number to Euro by default (use German as default. If using for example en-UK the currency symbol will display before the value)
    const euro = Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: decimals,
      minimumFractionDigits: 2
    });

    return euro.format(price);
  }

  /**
   * Update querystring paramater
   * */
  static updateURLParameter(url: string, param: string, paramVal: string): string {
    let newAdditionalURL = '';
    let tempArray = url.split('?');
    const baseURL = tempArray[0];
    const additionalURL = tempArray[1];
    let temp = '';

    if (additionalURL) {
      tempArray = additionalURL.split('&');
      for (let i = 0; i < tempArray.length; i++) {
        if (tempArray[i].split('=')[0] != param) {
          newAdditionalURL += temp + tempArray[i];
          temp = '&';
        }
      }
    }
    const rowsTxt = temp + '' + param + '=' + paramVal;

    return baseURL + '?' + newAdditionalURL + rowsTxt;
  }

  /**
   *  Example for lazy loading modules
   */
  //static async importNewsLetterModuleAsync(): Promise<typeof import('./newsletter-form')> {
  //  return await import('./newsletter-form');
  //}
}

export default Utilities;
