/**
 * Elevio logic
 * https://api-docs.elevio.help/en/categories/4-javascript-api
 */
class Elevio {

  /**
   * Init
   */
  static init(): void {

    // set current language
    // window._elev?.setLanguage(window.Printler.Locale); // use browser default language instead.

    const elevioHomeLinks: NodeListOf<HTMLElement> = document.querySelectorAll('.js-elevio-home');

    // setup event listeners for home links
    elevioHomeLinks.forEach((link) => {
      link?.addEventListener('click', (e: Event) => {
        Elevio.openHome(e);
      });
    });
  }

  /**
   * Open Elevio Module
   * @param e
   */
  static openHome(e: Event): void {
    e.preventDefault();
    window._elev?.openHome();
  }
}

export default Elevio;