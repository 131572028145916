import { ApiEndpoints } from '../enums';
import { Photo, UploadMediaResponse } from '../models';
import UrlService from './url-service';
import Utilities from './utilities';

/**
 * Service used to handle photos.
 */
export class PhotoService {
  /**
   * Retrieve a set of photos by their ids.
   * @param ids An array of photo ids to retrieve
   * @returns A promise that resolves to the retrieved photos.
   */
  static async getByIds(ids: number[]): Promise<Photo[]> {
    if (!ids.length) {
      return [];
    }

    const requestUrl = UrlService.getApiRequestUrl(ApiEndpoints.Photos, 'byids', {
      ids: ids.map(id => `${id}`),
    });

    try {
      const response = await fetch(requestUrl);
      return await response.json();
    } catch (e) {
      Utilities.log(e.message);
      return null;
    }
  }

  /**
   * Upload single media - update photo image
   * @param formData
   * @returns A promise that resolves to a json response object.
   */
  static async uploadMedia(formData: FormData): Promise<UploadMediaResponse> {
    const requestUrl = UrlService.getRenderUrl(ApiEndpoints.MemberPhotoUploadMedia);

    try {
      const response = await fetch(requestUrl, {
        method: 'post',
        body: formData
      });
      return await response.json();
    } catch (e) {
      Utilities.log(e.message);
      return null;
    }
  }
}
