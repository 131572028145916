import { EntityType } from '../enums';
import { FacebookEvent } from '../models/facebook-event.interface';
import Ecommerce from './ecommerce';
import FacebookConversionService from './facebook-conversion-service';
import FavouritesService from './favourites-service';

/**
 * Favourites
 */
class Favourites {
  static FavouritesContainerSelector = '.js-favourites-container';
  static FavouriteButtonSelector = '.js-toggle-favourite';
  static FavouriteCountSelector = '.js-favourite-count';
  static FavouriteMemberSelector = '.js-favourite-photograper';
  static FavouritesArray: number[] = [];
  static FavouriteMembersArray: number[] = [];
  static WallPlannerWallCount = 0;
  static FavouritesScrollPositionKey = 'PrintlerFavoritesScrollPosition';

  /**
   * Initialize
   */
  static async init(): Promise<void> {
    // fetch current favourite photos
    await this.refresh();

    // setup toggle click events
    Favourites.setupToggleClickEvents();
  }

  /**
   * Refreshes the favorite data and renders the dialog content
   */
  static async refresh(): Promise<void> {
    await FavouritesService.getAllByTypeAsync(EntityType.Poster).then(result => {
      this.FavouritesArray = result;
    });

    // fetch current favourite photographers
    await FavouritesService.getAllByTypeAsync(EntityType.Photographer).then(result => {
      this.FavouriteMembersArray = result;
    });

    await this.updateContent();
  }

  /**
   * Setup toggle click events
   * Listen to click events on entire document to fetch events on elements created async
   */
  static setupToggleClickEvents(): void {
    const toggleFavouriteCssClass = 'js-toggle-favourite';

    document.addEventListener('click', (e: Event) => {
      let button = e.target as HTMLElement;

      if (button.nodeName !== 'A') {
        button = button.closest(Favourites.FavouriteButtonSelector);
      }

      if (!button || !button.classList.contains(toggleFavouriteCssClass)) {
        // can't use FavouriteButtonSelector since it contains a '.' and won't match classlist
        return true;
      }

      if (button.closest(Favourites.FavouriteButtonSelector)) {
        const posterId = +button.getAttribute('data-photo-id');
        const memberId = +button.getAttribute('data-member-id');
        const wallId = button.getAttribute('data-wall-id');

        if (wallId) {
          // store.deleteStoredState(wallId);
          this.refresh();
        } else {
          let entityType = EntityType.Poster;
          let id = posterId;

          // no poster id -> member
          if (posterId === 0) {
            entityType = EntityType.Photographer;
            id = memberId;
          }

          this.toggleFavourite(id, entityType);
          e.preventDefault();
        }
      }
    });
  }

  /**
   * Toggle favourite
   * @param id - id of poster or member
   * @param entityType - poster or member
   */
  static toggleFavourite(id: number, entityType: EntityType): void {
    const combinedFavouritesArray = this.FavouritesArray.concat(this.FavouriteMembersArray);

    // if not exists
    if (combinedFavouritesArray.indexOf(id) === -1) {
      // add
      FavouritesService.add(id, entityType).then(() =>
        FavouritesService.getAllByTypeAsync(entityType).then(result => {
          if (entityType == EntityType.Poster) {
            this.FavouritesArray = result;
          } else {
            this.FavouriteMembersArray = result;
          }
          this.updateContent().then(() => {
            // push gtm event - wait 1 sec to make sure markup has been loaded
            setTimeout(() => { Ecommerce.pushAddToFavourites(entityType, id); }, 1000)
          });
        }),
      );

      // facebook AddToWishList event - disable eslint to allow non camelCase props

      // a unique event id to use with both the client(pixel) and serverside event
      const fbEventID = `${FacebookConversionService.getUniqueEventId()}`;

      const fbEvent = {
      /* eslint-disable */
        content_type: entityType === EntityType.Poster ? 'poster' : 'artist',
        content_ids: [id],
        currency: window.Printler.Currency,
      /* eslint-enable */
      }
      window.Printler.FacebookPixel.Track('AddToWishlist', fbEvent, { eventID: fbEventID });

      // conversion api event
      const fbServerEvent: FacebookEvent = {
        id: fbEventID,
        name: 'AddToWishlist',
        sourceUrl: window.location.href,
        customData: fbEvent,
      };

      // facebook conversion api
      FacebookConversionService.pushEventAsync(fbServerEvent);
    } else {
      // delete
      const containerElement: HTMLElement = document.querySelector(this.FavouritesContainerSelector);
      const scrollableContainer: HTMLElement = containerElement.querySelector('.o-favourites-modal__body');
     
      // save current scroll position
      localStorage.setItem(Favourites.FavouritesScrollPositionKey, `${scrollableContainer.scrollTop}`);

      FavouritesService.delete(id, entityType).then(() =>
        FavouritesService.getAllByTypeAsync(entityType).then(result => {
          if (entityType == EntityType.Poster) {
            this.FavouritesArray = result;
          } else {
            this.FavouriteMembersArray = result;
          }
          this.updateContent();
        }),
      );
    }
  }

  /**
   * Set favourites count and content
   */
  static async updateContent(): Promise<void> {
    const selectedCssClass = 'is-favourite';
    const hiddenCssClass = 'invisible';
    const favouriteCountBadge: HTMLElement = document.querySelector(this.FavouriteCountSelector);
    const favouriteButtons: NodeListOf<Element> = document.querySelectorAll(this.FavouriteButtonSelector);

    if (favouriteCountBadge == null)
      return;

    // update favourites count badge
    const combinedFavouritesArray = [...this.FavouritesArray, ...this.FavouriteMembersArray];

    if (combinedFavouritesArray.length > 0 || this.WallPlannerWallCount > 0) {
      favouriteCountBadge.innerHTML = `${combinedFavouritesArray.length + this.WallPlannerWallCount}`;
      favouriteCountBadge.classList.remove(hiddenCssClass);
    } else {
      favouriteCountBadge.innerHTML = '0';
      favouriteCountBadge.classList.add(hiddenCssClass);
    }

    // set selected buttons
    favouriteButtons.forEach((button: HTMLElement) => {
      let id = +button.getAttribute('data-photo-id');
      const favouriteType = button.getAttribute('data-type');

      if (favouriteType === 'member') {
        id = +button.getAttribute('data-member-id');
      }
      
      const icon: HTMLElement = button.querySelector('.fa-heart');

      if (combinedFavouritesArray.some(x => x === id)) {
        button.classList.add(selectedCssClass);
        icon?.classList.remove('fa-regular');
        icon?.classList.add('fa-solid');
        
      } else {
        button.classList.remove(selectedCssClass);
        icon?.classList.remove('fa-solid');
        icon?.classList.add('fa-regular');
      }
    });

    // populate favourites content
    this.populateFavouritesContent();
  }

  /**
   * Populate Favourites Container
   */
  static populateFavouritesContent(): void {
    const containerElement: HTMLElement = document.querySelector(this.FavouritesContainerSelector);

    FavouritesService.getRenderedHtml().then(response => {
      containerElement.innerHTML = response;

      // Bind favorite state to close modal when clicked
      containerElement.querySelectorAll('a[data-close]').forEach(node => {
        node.addEventListener('click', () => {
          const button = node.closest('.o-modal__content').querySelector('[data-dismiss="modal"]') as HTMLButtonElement;
          if (button) {
            button.click();
          }
        });
      });

      // scroll into position
      const scrollableContainer: HTMLElement = containerElement.querySelector('.o-favourites-modal__body');

      if (localStorage.getItem(Favourites.FavouritesScrollPositionKey) !== null) {
        scrollableContainer.scrollTo(0, +localStorage.getItem(Favourites.FavouritesScrollPositionKey));
      }
    });
  }
}

export default Favourites;
