import VoyadoService from './voyado-service';
import { VoyadoContact } from '../models';

/**
 * Initializes Checkout
 */
class Checkout {
  static init(): void {
    const isCheckoutPage: boolean = document.querySelector('body.checkoutpage') !== null;

    if (!isCheckoutPage) {
      return;
    }

    Checkout.setupDiscountCodeFormatting();

    Checkout.setupDiscountCodeFormEvents();

    // Voyado purchase - listen for event
    window.addEventListener('PurchaseCompleteEvent', (e: CustomEvent) => {
      // set customer
      Checkout.setCustomerContactId(e);

      // clear current cart
      VoyadoService.emptyCart(e.detail?.customData?.cart);
    });
  }

  /**
   * Fetch customer contact id and then set
   * @param e
   */
  static setCustomerContactId(e: CustomEvent): void {
    VoyadoService.getVoyadoContactAsync(e.detail.customData.email).then((contact: VoyadoContact) => {
      if (contact == null) {
        // console.warn('Could not get customer id');
        return;
      }

      VoyadoService.setVoyadoContactId(contact.id);
    });
  }

  /**
   * Show selected shipping
   * @param e - select change event
   */
  static setShipping(e: Event): void {
    const input = e.srcElement as HTMLInputElement;
    const shippingContainers: NodeListOf<HTMLElement> = document.querySelectorAll('.js-shipping');

    shippingContainers.forEach((container: HTMLElement) => {
      container.classList.add('d-none');
    });

    if (input.value !== '') {
      const matchedShippingContainer = document.querySelector('.js-shipping[data-country="' + input.value + '"]');
      matchedShippingContainer.classList.add('d-block');
    } else {
      const emptyShippingContainer = document.querySelector('.js-shipping[data-country=""]');
      emptyShippingContainer.classList.add('d-block');
    }
  }

  /**
   * Replaces special characters before submitting the form
   * Currently '–' gets replaced by a regular '-'
   */
  static setupDiscountCodeFormatting(): void {
    const discountCodeForm: HTMLFormElement = document.querySelector('.js-validate-discount-code-form');

    discountCodeForm?.addEventListener('submit', (e: Event) => {
      e.preventDefault();

      const codeInput: HTMLInputElement = discountCodeForm.querySelector('input.js-discount-code');

      // replace '–' with '-'
      codeInput.value = codeInput.value.replace('–', '-');

      discountCodeForm.submit();
    });
  }

  /**
   * Disable submit button after form submits to avoid multiple submits
   */
  static setupDiscountCodeFormEvents(): void {
    const discountCodeForms = document.querySelectorAll('.js-validate-discount-code-form');

    discountCodeForms.forEach((f) => {
      const submitBtn: HTMLButtonElement = f.querySelector('button[type=submit]');

      f.addEventListener('submit', () => {
        // disable the submit button to avoid multiple submits
        submitBtn.setAttribute('disabled', 'disabled');

      }, false);
    });
  }
}

export default Checkout;
