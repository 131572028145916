import { QueryParameters } from '../models';
import Settings from './settings';

class UrlService {
  /**
   * Get a request url
   * @param endpoint The base endpoint
   * @param action (optional) The endpoint action
   * @param queryParameters (optional) Query parameters to append to the url
   * @returns The resulting url
   */
  static getApiRequestUrl(endpoint: string, action?: string, queryParameters?: QueryParameters): string {
    const part = endpoint + (endpoint.endsWith('/') ? '' : '/') + (action ? action : '');
    return this._appendQueryParameters(`${Settings.API_BASE_URL}${part}`, {
      referrer: window.Printler.Current,
      culture: window.Printler.Locale,
      locale: window.Printler.Locale,
      _v: window.Printler.CacheBuster,
      ...(queryParameters || {}),
    });
  }

  /**
   * Get Api Url
   * @param endpoint - name of endpoint
   * @param entityType - poster or photographer
   * @param queryParameters (optional) Query parameters to add to the query
   * @returns The resulting url
   */
  static getApiUrl(endpoint: string, entityType: string, queryParameters?: QueryParameters): string {
    const apiUrl = `${Settings.API_BASE_URL}${endpoint}?type=${entityType}`;

    const qp = {
      type: entityType,
      referrer: window.Printler.Current,
      locale: window.Printler.Locale,
      _v: window.Printler.CacheBuster,
      ...(queryParameters || {}),
    };

    return this._appendQueryParameters(apiUrl, qp);
  }

  /**
   * Returns the render base url for the given endpoint
   * @param endpoint - the endpoint
   * @param queryParameters (optional) Query parameters to append
   * @returns The resulting url
   */
  static getRenderUrl(endpoint: string, queryParameters?: QueryParameters): string {
    return this._appendQueryParameters(`${Settings.API_RENDER_URL}${endpoint}`, {
      referrer: window.Printler.Current,
      culture: window.Printler.Locale,
      locale: window.Printler.Locale,
      _v: window.Printler.CacheBuster,
      ...(queryParameters || {}),
    });
  }

  /**
   * Appends a set of query parameters to an existing url.
   * If the url already contains query parameters the given query parameters are appended, not that duplicated query parameters
   * are NOT handled.
   * @param url The url to append query parameters to (may already contain query parameters)
   * @param queryParameters The query parameters to append
   * @returns The url with appended query parameters
   */
  private static _appendQueryParameters(url: string, queryParameters: QueryParameters | undefined | null): string {
    let apiUrl = url;

    if (queryParameters !== undefined && queryParameters !== null && typeof queryParameters === 'object') {
      const queryList: string[] = [];
      Object.keys(queryParameters).forEach(key => {
        const value = queryParameters[key];
        if (Array.isArray(value)) {
          value.forEach(v => {
            queryList.push(`${key}=${encodeURIComponent(v)}`);
          });
        } else if (value === undefined || value === null) {
          // Skip value
        } else {
          queryList.push(`${key}=${encodeURIComponent(value)}`);
        }
      });

      if (queryList.length) {
        if (apiUrl.includes('?')) {
          apiUrl += `&${queryList.join('&')}`;
        } else {
          apiUrl += `?${queryList.join('&')}`;
        }
      }
    }

    return apiUrl;
  }
}

export default UrlService;
