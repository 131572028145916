import Carousel from './carousel';

/**
 * ´The slideshow modal
 */
class SlideshowModal {
  static init(): void {

    const modalTriggers: NodeListOf<HTMLElement> = document.querySelectorAll('.js-toggle-slideshow');

    if (!modalTriggers || modalTriggers.length === 0) {
      return;
    }

    modalTriggers.forEach((triggerElement) => {
      triggerElement.addEventListener('click', (e: Event) => {
        e.preventDefault();
        this.setupModal(e.currentTarget as HTMLElement);
      });
    });
  }

/**
 * Setup slideshow modal
 * @param triggerElement - slideshow toggle link
 */
  static setupModal(triggerElement: HTMLElement): void {
    const slideIndex = triggerElement.dataset.slide;
    const backDropClass = 'backdrop-dark';
    const modal: HTMLElement = document.querySelector('#slideshowModal');
    const bodyElement = document.querySelector('body');
    const modalButton: HTMLButtonElement = document.querySelector('.js-toggle-slideshow-btn');

    if (modalButton) {
      modalButton.click();
    }

    // add dark backdrop
    bodyElement.classList.add(backDropClass);

    // remove backdrop color on close
    modal?.addEventListener('hidden.bs.modal', () => {
      bodyElement.classList.remove(backDropClass);
    }, false);

    // set correct slide
    modal?.addEventListener('shown.bs.modal', () => {
      Carousel.ProductSlider.goTo(+slideIndex);
      Carousel.ProductSlider.updateSliderHeight();
    }, false);
  }
}

export default SlideshowModal;
