import { tns, TinySliderInstance } from 'tiny-slider/src/tiny-slider';
/**
 * Initializes the hero image slider
 */
class Carousel {
  static ProductSlider: TinySliderInstance;
  static FramesSlider: TinySliderInstance;
  static FeaturedContentSlider: TinySliderInstance;
  static AddonProductsSlider: TinySliderInstance;

  static ProductsSliders: Array<TinySliderInstance>;
  static ModalSliders: Array<TinySliderInstance>;

  static init(): void {
    // Carousel.initProductSlideshow();
    Carousel.initCleanProductSlideshow();
    Carousel.initHeroCarousel();
    Carousel.initProductCardSlider();
    Carousel.initFramesSlideshow();
    Carousel.initFeaturedContentSlideShow();
    Carousel.initAddonSwiper();
  }

  /**
   * Init Hero Carousel
   */
  static initHeroCarousel(): void {
    const containerSelector = '.js-hero-carousel';
    const carouselContainer: NodeListOf<HTMLElement> = document.querySelectorAll(containerSelector);
    const autoPlay = !!document.querySelector('.js-carousel-autoplay');

    if (carouselContainer.length === 0) {
      return;
    }

    tns({
      container: containerSelector,
      items: 1,
      mode: 'carousel',
      controlsContainer: '.js-hero-controls',
      autoplay: autoPlay,
      autoplayButton: autoPlay ? '.js-carousel-autoplay' : false,
      autoplayHoverPause: autoPlay,
      autoHeight: true,
      rewind: true,
      lazyload: true,
      navContainer: '.js-hero-carousel-indicators',
    });
  }

  /**
   * Init Modal Product Slideshow
   * @param selector - the selector for the current slideshow container
   */
  static initProductSlideshow(selector = '.js-slideshow-content-container-art-prints'): void {
    const containerSelector = `.photopage .js-product-slideshow`;
    const container: HTMLElement = document.querySelector(selector);

    if (container === null) {
      return;
    }

    Carousel.ProductSlider = tns({
      container: containerSelector,
      loop: true,
      navPosition: 'bottom',
      controlsContainer: `${selector} .js-slideshow-controls`,
      items: 1,
      // navAsThumbnails: false,
      mouseDrag: true,
      controls: true,
      // startIndex: 0,
      autoHeight: true,
      lazyload: true, // class .tns-lazy-img need to be set on every image you want to lazyload if option lazyloadSelector is not specified
    });

    // update slider height is needed when using autoHeight and lazyLoad
    const images = container.querySelectorAll('img');
    images.forEach(img => {
      img.addEventListener('load', () => {
        Carousel.ProductSlider.updateSliderHeight();
      })
    });
  }

  /**
   * Refresh product slideshow and go to first slide
   */
  static refreshProductSlideshow(): void {
    if (Carousel.ProductsSliders) {
      Carousel.ProductsSliders.forEach(slider => {
        slider.refresh();
        slider.goTo('first');
      });
    }
   }

  /**
   * Init mobile product slideshow
   * @param selector - the wrapping container selector
   */
  static initCleanProductSlideshow(): void {
    const containerSelector = '.js-product-carousel:not(.tns-slider)';
    const containers: NodeListOf<HTMLElement> = document.querySelectorAll(containerSelector);

    if (containers === null || containers?.length == 0) {
      return;
    }

    const productTypeSliders: Array<TinySliderInstance> = [];

    containers.forEach((container) => {
      const productType = container.dataset.type;
      const slider = tns({
        container: containerSelector,
        mouseDrag: true,
        center: true,
        items: 1,
        edgePadding: 0,
        gutter: 1,
        preventScrollOnTouch: 'auto', // prevent page scrolling when swipe:ing.
        controls: false,
        nav: true,
        autoHeight: false,
        lazyload: true,
        loop: false,
        prevButton: `.js-prev-btn-${productType.toLowerCase()}`,
        nextButton: `.js-next-btn-${productType.toLowerCase() }`,
        responsive: {
          992: {
            controls: true,
            controlsText: ['', ''],
            arrowKeys: true,
          }
        }
      });

      // set new height when video is loaded
      const videos = container.querySelectorAll('video');
      videos?.forEach(video => {
        video.addEventListener('loadeddata', function () {
          slider.updateSliderHeight();
        });
      });

      productTypeSliders.push(slider);
    });

    Carousel.ProductsSliders = productTypeSliders;

    Carousel.initLightboxModalProductSlideshow();
  }

  /** Initialize carousel in modal */
  static initLightboxModalProductSlideshow(): void {
    const photoModals = document.querySelectorAll('.js-modal-carousel');

    photoModals.forEach((photoModal) => {
      // lightbox modal
      photoModal.addEventListener('show.bs.modal', (event: any) => { // eslint-disable-line
        const index = event.relatedTarget.dataset.index;
        Carousel.initCleanModalProductSlideshow(index);
      }, false);

      // destroy ligtbox carousels when modal is hidden
      photoModal.addEventListener('hidden.bs.modal', (event: any) => { // eslint-disable-line
        if (Carousel.ModalSliders) {
          Carousel.ModalSliders.forEach(slider => {
            slider.destroy();
          });
        }
      }, false);
    });
  }

  /**
   * Initialize modal carousel/swiper
   * @returns
   */
  static initCleanModalProductSlideshow(startIndex: number): void {
    const containerSelector = '.js-modal-product-carousel:not(.tns-slider)';
    const containers: NodeListOf<HTMLElement> = document.querySelectorAll(containerSelector);

    if (containers === null || containers?.length == 0) {
      return;
    }

    const modalTypeSliders: Array<TinySliderInstance> = [];

    containers.forEach((container) => {
      const productType = container.dataset.type;
      const slider = tns({
        container: containerSelector,
        mouseDrag: true,
        center: true,
        items: 1,
        edgePadding: 0,
        gutter: 1,
        preventScrollOnTouch: 'auto', // prevent page scrolling when swipe:ing.
        controls: false,
        nav: true,
        autoHeight: false,
        lazyload: true,
        loop: false,
        prevButton: `.js-modal-prev-btn-${productType.toLowerCase()}`,
        nextButton: `.js-modal-next-btn-${productType.toLowerCase()}`,
        responsive: {
          992: {
            controls: true,
            controlsText: ['', ''],
            arrowKeys: true,
          }
        }
      });

      // set new height when video is loaded
      const videos = container.querySelectorAll('video');
      videos?.forEach(video => {
        video.addEventListener('loadeddata', function () {
          slider.updateSliderHeight();
        });
      });

      modalTypeSliders.push(slider);

      slider.refresh();
      slider.goTo(startIndex);
    });

    Carousel.ModalSliders = modalTypeSliders;
  }

  /**
   * Init product card slider/carousel
   * */
  static initProductCardSlider(): void {
    const containersSelector = '.js-product-card-slider:not(.tns-slider)'; // only select not initiated
    const productCardSliderElements: NodeListOf<HTMLElement> = document.querySelectorAll(containersSelector);

    if (productCardSliderElements === null) {
      return;
    }

    const productCardSliders = [];

    productCardSliderElements.forEach((elm: HTMLElement) => {
      const slider = tns({
        container: elm,
        controls: false,
        mouseDrag: true,
        lazyload: true, // class .tns-lazy-img need to be set on every image you want to lazyload if option lazyloadSelector is not specified and set data-src
        responsive: {
          900: {
            controls: true,
          }
        }
      });

      productCardSliders.push(slider);
    });
  }

  /**
   * Init Modal Frames Slideshow
   */
  static initFramesSlideshow(): void {
    const containerSelector = '.js-frames-slideshow';
    const container: HTMLElement = document.querySelector(containerSelector);

    if (container === null) {
      return;
    }

    const modal: HTMLElement = document.querySelector('.js-frames-info-modal');
    const controls: HTMLElement = modal.querySelector('.js-slideshow-controls');

    Carousel.FramesSlider = tns({
      container: containerSelector,
      controls: false,
      items: 1,
      mouseDrag: true,
      nav: false,
      lazyload: true, // Class .tns-lazy-img need to be set on every image you want to lazyload if option lazyloadSelector is not specified
    });

    const prev = controls.querySelector('.js-slideshow-controls-prev');
    const next = controls.querySelector('.js-slideshow-controls-next');

    prev.addEventListener('click', () => {
      Carousel.FramesSlider.goTo('prev');
    });

    next.addEventListener('click', () => {
      Carousel.FramesSlider.goTo('next');
    });
  }

  /**
   * Init Featured Content Slideshow
   */
  static initFeaturedContentSlideShow(): void {
    const containerSelector = '.js-featured-slideshow:not(.tns-slider)';
    const containers: NodeListOf<HTMLElement> = document.querySelectorAll(containerSelector);
 
    if (containers === null) {
      return;
    }

    const featuredSliders = [];

    containers.forEach(() => {
      const slider = tns({
        container: containerSelector,
        mouseDrag: true,
        center: false,
        items: 1,
        edgePadding: 50,
        gutter: 18,
        preventScrollOnTouch: 'auto', // prevent page scrolling when swipe:ing.
        controls: false,
        nav: false,
        autoHeight: true,
        autoWidth: true,
        lazyload: true,
        loop: false,
        responsive: {
          992: {
            items: 4,
            controls: true,
            controlsText: ['', ''],
            autoWidth: false,
            arrowKeys: true,
          }
        }
      });

      featuredSliders.push(slider);
    });
  }

  /**
   * Initialize addon carousel/swiper
   * */
  static initAddonSwiper(): void {
    const containerSelector = '.js-addon-swipe-wrapper:not(.tns-slider)';
    const container: HTMLElement = document.querySelector(containerSelector);

    if (container === null) {
      return;
    }

    Carousel.AddonProductsSlider = tns({
      container: containerSelector,
      mouseDrag: true,
      preventScrollOnTouch: 'auto', // prevent page scrolling when swpie:ing.
      controls: false,
      nav: false,
      autoHeight: false,
      center: false,
      lazyload: true,
      items: 5,
      edgePadding: 0,
      loop: false,
    });
  }
}

export default Carousel;