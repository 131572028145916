import Utilities from './utilities';

/**
 * Alert logic
 */
class Alert {
  static AlertCookieKey = 'PrintlerBannerDismissed';

  /**
   * Init
   */
  static init(): void {
    const dismissAlertButton: HTMLButtonElement = document.querySelector('.js-alert-dismiss');
    const dismissAlert: HTMLElement = document.querySelector('.js-alert');

    if (!dismissAlert) {
      return;
    }

    // if cookie not exists add class .show
    const alertCookie = Utilities.getCookie(Alert.AlertCookieKey);
    const cacheKey = dismissAlert.getAttribute('data-cache-key');

    if (!alertCookie || alertCookie != cacheKey) {
      dismissAlert.classList.add('show');
    }

    if (dismissAlertButton != null) {
      dismissAlertButton.addEventListener('click', () => {
        this.handleAlertDismissClick(dismissAlertButton, cacheKey);
      }, true);
    }
  }

  /**
   * Handle alert dismiss button click
   * @param dismissButton - the dismiss button
   * @param cacheKey - the current cache key
   */
  static handleAlertDismissClick(dismissButton: HTMLButtonElement, cacheKey: string): void {
    const alertElement: HTMLElement = dismissButton.closest('.alert-dismissible');

    // hide alert
    alertElement.classList.remove('show');

    Utilities.setCookie(Alert.AlertCookieKey, cacheKey, 7);
  }
}

export default Alert;