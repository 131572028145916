import { ApiEndpoints } from '../enums';
import Ecommerce from './ecommerce';
import UrlService from './url-service';
import Utilities from './utilities';
import VoyadoService from './voyado-service';
import { DictionaryService } from './dictionary-service';

/**
 * The newsletter form
 */
class NewsLetterForm {
  /*
   * Render newsletter form
   */
  static async init(): Promise<void> {
    const newsLetterContainer: HTMLElement = document.querySelector('#newsletter-form');

    if (!newsLetterContainer) {
      return;
    }

    // fetch html
    await NewsLetterForm.getNewsletterFormHtml()
      .then(result => {
        newsLetterContainer.innerHTML = result;

        const signUpForm = document.getElementById('voyado-embedded-subscribe-form') as HTMLFormElement;
        const errorMessageContainer: HTMLElement = document.querySelector('.js-voyado-error');
        const errorMessage: HTMLElement = errorMessageContainer?.querySelector('.js-voyado-error-msg');

        // on form submit
        signUpForm.addEventListener('submit', (e) => {
          e.preventDefault();
          const formData = new FormData(signUpForm);
          const emailInput = formData.get('voyado-email');
          const countrySelect = formData.get('voyado-country');
          const msg: HTMLElement = newsLetterContainer.querySelector('.js-newsletter-form-sent');

          // hide previous errors
          if (!errorMessageContainer.classList.contains('d-none')) {
            errorMessageContainer.classList.add('d-none');
          }

          // push to Voyado
          VoyadoService.addVoyadoContactAsync(`${emailInput}`, `${countrySelect}`, window.Printler.StoreId, 'footer').then(result => {
            if (result.ok) {
              const form = newsLetterContainer.querySelector('form');

              form.classList.add('d-none');
              msg.classList.remove('d-none');

              // push sign up event to GTM
              Ecommerce.pushNewsLetterSignupEvent();

            } else {
              const errorCode = result.ErrorMessage;
              const dictionaryKey = `VoyadoErrorCode${errorCode}`;

              DictionaryService.translate(dictionaryKey, window.Printler.DefaultErrorMessage, 'VoyadoErrors').then((value) => {
                errorMessage.innerHTML = value;
                errorMessageContainer.classList.remove('d-none');
              });
            }
          });

          Ecommerce.pushNewsLetterSignupEvent();
        });
      })
      .catch((error: Error) => {
        Utilities.log(error.message);
      });
  }

  /**
   * Get newsletter html
   */
  static async getNewsletterFormHtml(): Promise<string> {
    const requestOptions = {
      method: 'GET',
    };

    const requestUrl = UrlService.getRenderUrl(ApiEndpoints.NewsletterForm);

    try {
      const response = await fetch(requestUrl, requestOptions);
      return await response.text();
    } catch (error) {
      if (error) {
        Utilities.log(error);
        return null;
      }
    }
  }
}

export default NewsLetterForm;
