import { ApiEndpoints, Margin } from '../enums';
import { CartItemAdd, FacebookEvent, EstimatedPrice } from '../models';
import Cart from './cart';
import CartService from './cart-service';
import UrlService from './url-service';
import Utilities from './utilities';
import Ecommerce from './ecommerce';
import FacebookConversionService from './facebook-conversion-service';

/**
 * Initializes Gallery Wall logic
 */
class GalleryWall {
  static WallProductSelector = '.js-wall-product';
  static AddToCartSelector = '.js-add-products-to-cart';
  static AddItemToCartSelector = '.js-add-gallery-item-to-cart';
  static GalleryWallSelector = '.js-gallery-wall';

  static init(): void {
    /* deactivated gallery wall modals - due to bug in unique wall discount code price calculation. ShopController -> CartItems */
    const galleryWallTriggers: NodeListOf<HTMLAnchorElement> = null; //  document.querySelectorAll('a.gallerywallpage');

    // if modal triggers exists add event listener
    if (galleryWallTriggers && galleryWallTriggers.length > 0) {
      // const modal: HTMLElement = document.querySelector('#galleryWallModal');

      // if (!modal) {
      //   GalleryWall.createWallModalMarkup().then(() => {
      //     GalleryWall.setupWallTriggerEvents(galleryWallTriggers);
      //   });
      // } else {
      //   GalleryWall.setupWallTriggerEvents(galleryWallTriggers);
      // }
    } else {
      // no modal - full view page
      const wall: HTMLElement = document.querySelector(GalleryWall.GalleryWallSelector);

      // if not modal content
      if (wall && wall.classList.contains('js-full-view')) {
        GalleryWall.setupGalleryProductsFormEvents();
        GalleryWall.setupMarginSelectEvents();

        // initial total
        GalleryWall.calculateTotalPrice();
      }
    }
  }

  /**
   * Setup click events for gallery wall modal
   * @param triggers - list of anchor elements
   */
  static setupWallTriggerEvents(triggers: NodeListOf<HTMLAnchorElement>): void {
    if (Utilities.isMobileViewportSize()) {
      return;
    }

    triggers.forEach(modalTrigger => {
      modalTrigger.addEventListener('click', GalleryWall.triggerWallModal, true);
    });
  }

  /**
   * Trigger gallery wall modal open
   * @param event - click event
   */
  static triggerWallModal(e: Event): void {
    e.preventDefault();

    const link = e.currentTarget as HTMLAnchorElement;
    const galleryWallTrigger: HTMLElement = document.querySelector('.js-gallery-modal-trigger');
    const modal: HTMLElement = document.querySelector('#galleryWallModal');
    const galleryBodyContainer: HTMLIFrameElement = modal.querySelector('.js-gallery-modal-body');
    const bodyElement: HTMLElement = document.querySelector('body');
    const backdropClass = 'backdrop-dark';

    // trigger modal from hidden button
    galleryWallTrigger.click();

    // set content
    GalleryWall.fetchGalleryWall(+link.dataset.pageId).then(result => {
      galleryBodyContainer.innerHTML = result;

      // setup form change event after been loaded
      // wait a short while to make sure content is fully loaded
      setTimeout(() => {
        GalleryWall.setupGalleryProductsFormEvents();
      }, 150);

      // initial total
      GalleryWall.calculateTotalPrice();
    });

    // set dark backdrop
    modal.addEventListener(
      'show.bs.modal',
      () => {
        bodyElement.classList.add(backdropClass);
      },
      false,
    );

    // remove backdrop color on close
    modal.addEventListener(
      'hidden.bs.modal',
      () => {
        bodyElement.classList.remove(backdropClass);
      },
      false,
    );
  }

  /**
   * Fetch gallery wall by id
   * @param id - node id
   */
  static async fetchGalleryWall(id: number): Promise<string> {
    const requestOptions = {
      method: 'GET',
    };

    const requestUrl = UrlService.getRenderUrl(ApiEndpoints.GalleryWall, {
      pageid: id,
    });

    try {
      const response = await fetch(requestUrl, requestOptions);
      return await response.text();
    } catch (error) {
      if (error) {
        Utilities.log(error);
        return null;
      }
    }
  }

  /**
   * Toggle product options form
   * @param btn - toggle btn
   */
  static toggleProductOptionsForm(btn: HTMLElement): void {
    const productContainer: HTMLElement = btn.closest('article');

    if (productContainer.classList.contains('is-active')) {
      productContainer.classList.remove('is-active');
    } else {
      productContainer.classList.add('is-active');
    }
  }

  /**
  * Setup click event for the margin selection
  */
  static setupMarginSelectEvents(): void {
    const wallProducts: NodeListOf<HTMLElement> = document.querySelectorAll(GalleryWall.WallProductSelector);

    wallProducts.forEach(product => {
      const marginOptions: Array<HTMLInputElement> = Array.from(product.querySelectorAll('input[name="options[Margin]'));
      const withMarginRadio: HTMLInputElement = marginOptions.find(field => field.getAttribute('name') === 'options[Margin]' && field.getAttribute('value') === Margin.WithMargin) as HTMLInputElement;

      product.dataset.options = withMarginRadio?.checked ? Margin.WithMargin : Margin.WithoutMargin;

      marginOptions.forEach(inputOption => {
        // add click listener
        inputOption.addEventListener('click', () => {
          product.dataset.options = inputOption.value === Margin.WithMargin ? Margin.WithMargin : Margin.WithoutMargin;
        });
      });
    });
  }

  /**
   * Setup change event for wall products form
   */
  static setupGalleryProductsFormEvents(): void {
    const wallProductsForms: NodeListOf<HTMLFormElement> = document.querySelectorAll('.js-gallery-products-form');
    const addWallProductsToCartButton: HTMLButtonElement = document.querySelector(GalleryWall.AddToCartSelector);
    const addItemToCartBtnList: NodeListOf<HTMLButtonElement> = document.querySelectorAll(GalleryWall.AddItemToCartSelector);

    // setup options
    const formToggleButtons: NodeListOf<HTMLElement> = document.querySelectorAll('.js-toggle-options');

    formToggleButtons.forEach(btn => {
      btn.addEventListener('click', (e: Event) => {
        e.preventDefault();
        GalleryWall.toggleProductOptionsForm(btn);
      });
    });

    // form change events
    wallProductsForms.forEach(form => {
      form.addEventListener('change', (e: Event) => {
        const input = e.srcElement as HTMLSelectElement;
        const wallProduct: HTMLElement = input.closest(GalleryWall.WallProductSelector);
        const selectedAddonTextContainer: HTMLElement = wallProduct.querySelector('.js-wallitem-selected-addon');

        //  if NOT margin - set addon price, addon id and addon text
        if (input.getAttribute('name') !== 'options[Margin]') {
          const selectedOption = input.options[input.selectedIndex];
          const addonPrice = this.currencyFormat(selectedOption.dataset.priceValue); // +selectedOption.dataset.priceValue.replace(',', '.'); // replace comma to be able to parse to int
          // const currency = window.Printler.PriceFormat.substring(1);

          if (isNaN(addonPrice) || addonPrice === 0) {
            
            if (+wallProduct.dataset.currentAddonPrice > 0) {
              const price = Math.round(+wallProduct.dataset.priceCalculated - +wallProduct.dataset.currentAddonPrice);
              // Utilities.log(`Total poster item price ${Math.round(price)} ${currency}`)
              wallProduct.dataset.priceCalculated = `${price}`;
            }

            wallProduct.dataset.addonId = '';
            wallProduct.dataset.currentAddonPrice = '0';

            // set default addon text
            selectedAddonTextContainer.innerText = selectedAddonTextContainer.dataset.defaultText;
          }
          else {
            const total = Math.round(this.currencyFormat(wallProduct.dataset.priceCalculated) + addonPrice);
            
            // Utilities.log(`Total poster item price ${Math.round(total)}`);
            // wallProductPrice.innerHTML = `${total} ${currency}`;
            wallProduct.dataset.priceCalculated = `${total}`;
            wallProduct.dataset.addonId = `${selectedOption.value}`;
            wallProduct.dataset.currentAddonPrice = `${addonPrice}`;

            // set addon text
            selectedAddonTextContainer.innerText = selectedOption.text;
          }

          // calculate new total
          GalleryWall.calculateTotalPrice();
        } else {
          // set margin option value
          wallProduct.dataset.options = input.value;
        }
      });
    });

    // add wall products to cart click event
    addWallProductsToCartButton.addEventListener('click', (e: Event) => {
      e.preventDefault();

      const wallItems: NodeListOf<HTMLElement> = document.querySelectorAll(GalleryWall.WallProductSelector);

      // loop all products and add to cart
      this.addProductsToCart(wallItems);

      // dismiss modal when done
      const galleryBodyContainer: HTMLIFrameElement = document.querySelector('.js-gallery-modal-body');

      if (galleryBodyContainer && galleryBodyContainer.offsetLeft > 0) {
        galleryBodyContainer.innerHTML =
          '<p class="my-4 text-center h1">Items added to cart!<div class="text-center"><div class="a-spinner a-spinner--sm mx-auto" role="status"></div></div></p>';

        // dismiss modal
        const dismissButton: HTMLElement = document.querySelector('.js-gallery-modal-dismiss');

        setTimeout(() => {
          dismissButton.click();
        }, 1000);
      } else {
        // btn animation
        Cart.addToCartBtnAnimation(e.currentTarget as HTMLButtonElement);
      }
    });

    // setup gallery item to cart event listener
    addItemToCartBtnList.forEach(btn => {
      btn.addEventListener('click', (e: Event) => {
        e.preventDefault();
        GalleryWall.addGalleryItemToCart(btn);
      });
    })
  }

  /**
   * Replaces comma with dot to be able to parse to a number
  */
  static currencyFormat(value: string): number {
    return +value.replace(',', '.');
  }

  /**
   * Add gallery item to cart
   */
  static addGalleryItemToCart(btn: HTMLElement): void {
    const wallItem: HTMLElement = btn.closest(GalleryWall.WallProductSelector);
    const productGroup: HTMLElement = wallItem.querySelector('.js-product-group');
    const productGroupInputs: NodeListOf<HTMLInputElement> = productGroup.querySelectorAll('input[name="selectedProductId"]');
    const productGroupArray = Array.from(productGroupInputs).filter(x => x.getAttribute('disabled') === null); // filter disabled fields
    const selectedProductField = productGroupArray.find(field => field.getAttribute('name') === 'selectedProductId' && field.getAttribute('checked') !== null) as HTMLInputElement;

    const marginOptions: Array<HTMLInputElement> = Array.from(wallItem.querySelectorAll('input[name="options[Margin]'));
    const withMarginRadio: HTMLInputElement = marginOptions.find(field => field.getAttribute('name') === 'options[Margin]' && field.getAttribute('value') === Margin.WithMargin) as HTMLInputElement;

    // get selected value or initial value
    const selectedProductId = selectedProductField.value ?? +wallItem.dataset.productId;

    const cartItem: CartItemAdd = {
      photoId: +wallItem.dataset.photoId,
      productId: +selectedProductId,
      addon: +wallItem.dataset.addonId,
      options: {
        Margin: withMarginRadio?.checked ? Margin.WithMargin : Margin.WithoutMargin,
      },
    };

    // add product to cart
    CartService.addProduct(cartItem).then(() => {
      Cart.addToCartBtnAnimation(btn);
      Cart.renderCart();

       // wait for cart to render then push datalayer add to cart event
       setTimeout(() => {
         Ecommerce.pushAddToCartEvent(cartItem);

         // add facebook event.
         const eventId = `${FacebookConversionService.getUniqueEventId()}`;

         // fetch price
         const productPrice = selectedProductField?.getAttribute('data-price-value');

         /* eslint-disable @typescript-eslint/camelcase */
         const customData = {
           content_type: 'product',
           content_ids: cartItem.photoId,
           currency: window.Printler.Currency,
           value: productPrice,
         };
         /* eslint-enable @typescript-eslint/camelcase */

         window.Printler.FacebookPixel.Track('AddToCart', customData, { eventID: eventId });

         const fbEvent: FacebookEvent = {
           id: eventId,
           name: 'AddToCart',
           sourceUrl: window.location.href,
           customData: customData,
         };

         // facebook conversion api
         FacebookConversionService.pushEventAsync(fbEvent);

      }, 1000);
    });
  }

  /**
   * Loop all wall products and calculate total price
   */
  static calculateTotalPrice(): void {
    const wallProducts: NodeListOf<HTMLElement> = document.querySelectorAll(GalleryWall.WallProductSelector);
    const totalPriceElement: HTMLElement = document.querySelector('.js-wall-product-total-price');
    const totalPriceWithDiscountElement: HTMLElement = document.querySelector('.js-wall-product-total-price-with-discount');

    const currencyFormatted = window.Printler.PriceFormat.substring(1);

    let totalPrice = 0.0;

    wallProducts.forEach(product => {
      const price = product.dataset.priceCalculated;
      const priceNumeric = this.currencyFormat(price); // +price.replace(',', '.');
      
      totalPrice = totalPrice + priceNumeric;
    });

    // round to two decimal places
    const totalPriceRounded = Math.round((totalPrice + Number.EPSILON) * 100) / 100;

    // display total price with comma
    totalPriceElement.innerHTML = `${totalPriceRounded.toString().replace('.', ',')} ${currencyFormatted}`;

    // get price with discount if wall has discount code
    if (totalPriceWithDiscountElement) {
      const wallId = totalPriceWithDiscountElement.dataset.galleryWallId;
      const cartItems: CartItemAdd[] = [];

      wallProducts.forEach(wallItem => {
        const cartItem: CartItemAdd = {
          photoId: +wallItem.dataset.photoId,
          productId: +wallItem.dataset.productId,
          addon: +wallItem.dataset.addonId,
          options: {
            Margin: wallItem.dataset.options === Margin.WithMargin ? Margin.WithMargin : Margin.WithoutMargin,
          },
        };
        cartItems.push(cartItem);
      });

      if (+wallId > 0) {
        CartService.getEstimatedPrice(cartItems, { wallId: +wallId }).then((price: EstimatedPrice) => {
          // set new discount price
          totalPriceWithDiscountElement.innerHTML = `${price.TotalFormatted}`;
        });
      }
    }
  }

  /**
   * Add products to cart from a wall item element
   * @param wallItems - HTMLElement
   */
  static addProductsToCart(wallItems: NodeListOf<HTMLElement>): void {
    const cartItems: CartItemAdd[] = [];

    wallItems.forEach(wallItem => {
      const cartItem: CartItemAdd = {
        photoId: +wallItem.dataset.photoId,
        productId: +wallItem.dataset.productId,
        addon: +wallItem.dataset.addonId,
        options: {
          Margin: wallItem.dataset.options === Margin.WithMargin ? Margin.WithMargin : Margin.WithoutMargin,
        },
      };
      cartItems.push(cartItem);
    });

    // add products to cart
    CartService.addProducts(cartItems).then(() => {
      // render cart content
      Cart.renderCart();

       // wait for cart to render then push datalayer add to cart event
       setTimeout(() => {
        cartItems.forEach((cartItem) => {
          Ecommerce.pushAddToCartEvent(cartItem);
        });

         // add facebook event.
         /* eslint-disable @typescript-eslint/camelcase */
         const customData = {
           content_type: 'product',
           content_ids: cartItems.map(x => x.photoId),
           currency: window.Printler.Currency,
         };
         /* eslint-enable @typescript-eslint/camelcase */

         const fbEvent: FacebookEvent = {
           id: `${FacebookConversionService.getUniqueEventId()}`,
           name: 'AddToCart',
           sourceUrl: window.location.href,
           customData: customData,
         };

         // facebook conversion api
         FacebookConversionService.pushEventAsync(fbEvent);
      }, 1000);
    });
  }

  /**
   * Create wall modal markup
   */
  static async createWallModalMarkup(): Promise<void> {
    const modalHtml = `<button type="button" class="invisible js-gallery-modal-trigger" data-toggle="modal" data-target="#galleryWallModal"></button>
                       <div class="o-modal o-modal--dark o-modal--xl fade" id="galleryWallModal" data-backdrop="static" tabindex="-1" role="dialog" aria-hidden="true" aria-labelledby="galleryModalLabel">
                        <button type="button" class="o-slideshow-modal__close a-btn js-gallery-modal-dismiss" data-dismiss="modal" aria-label="Close">
                            <i aria-hidden="true" class="fas fa-times"></i>
                        </button>
                        <div class="o-modal__dialog" role="document">
                            <div class="o-modal__content">
                                <header class="o-modal__header my-3">
                                    <h3 id="galleryModalLabel" class="o-modal__title h1 is-italic text-white pl-3">Gallery Wall</h3>
                                </header>

                                <div class="o-modal__body py-0 px-0 js-gallery-modal-body">
                                    <div class="text-center">
                                        <div class="a-spinner a-spinner--sm mx-auto" role="status"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div`;

    const body: HTMLElement = document.querySelector('body');

    body.innerHTML = body.innerHTML + modalHtml;
  }
}

export default GalleryWall;
