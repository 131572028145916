/**
 * Expand text logic
 */
class TextExpand {
  /**
   * Init
   */
  static init(): void {
    const textContainer: HTMLElement = document.querySelector('.js-text-overflow');

    if (textContainer == null)
      return;

    const expandBtn: HTMLButtonElement = document.querySelector('.js-text-expand');
    const overlay: HTMLElement = document.getElementById('text-overflow-overlay');
    let expanded = false;

    expandBtn.addEventListener('click', function () {
      if (!expanded) {
        textContainer.style.maxHeight = '600px'; // Remove height limit
        overlay.style.display = 'none';         // Hide the overlay
        expandBtn.style.display = 'none';    // Update button text
      } else {
        textContainer.style.maxHeight = '50px'; // Reset to initial height
        overlay.style.display = 'block';         // Show overlay again
        expandBtn.style.display = 'block';     // Update button text
      }
      expanded = !expanded;                      // Toggle state
    });
  }
 }

export default TextExpand;