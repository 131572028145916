import Utilities from './utilities';

/**
 * Campaign modal logic
 */
class CampaignModal {
  static PopupCookieKey = 'PrintlerPopupDismissed';
  static CampaignModalElement: HTMLElement = document.getElementById('campaignModal');
  /**
   * Init
   */
  static init(): void {
    if (!CampaignModal.CampaignModalElement) {
      return;
    }

    // if auto display on
    if (CampaignModal.CampaignModalElement.dataset.autoDisplay === 'True') {
      this.setupAutomaticDisplay();
    }
  }

  /**
   * Display modal automatically on page load if not already displayed
  */
  static setupAutomaticDisplay(): void {
    const dismissModalButton: HTMLButtonElement = CampaignModal.CampaignModalElement?.querySelector('.close');
    const actionButton: HTMLButtonElement = CampaignModal.CampaignModalElement?.querySelector('.js-campaign-modal-go-button');

    // if cookie not exists or if cookie value differ from cacheKey - show popup
    const popupCookie = Utilities.getCookie(CampaignModal.PopupCookieKey);
    const cacheKey = CampaignModal.CampaignModalElement.getAttribute('data-cache-key');

    if (!popupCookie || popupCookie != cacheKey) {
      setTimeout(() => {
        const triggerModalButton: HTMLButtonElement = document.querySelector('#campaignModalTrigger');
        triggerModalButton.click();
      }, 2000)
    }

    // set cookie on close
    if (dismissModalButton != null) {
      dismissModalButton.addEventListener('click', () => {
        this.handleCampaignModalDismissClick(cacheKey);
      }, true);
    }

    // set cookie on GO
    if (actionButton != null) {
      actionButton.addEventListener('click', () => {
        this.handleCampaignModalDismissClick(cacheKey);
      }, true);
    }
  }

  /**
   * Handle popup dismiss button click
   * @param cacheKey - modal last update date
   */
  static handleCampaignModalDismissClick(cacheKey: string): void {
    const event = new Event('hide.bs.modal');

    // listen for the close event
    document.addEventListener('hide.bs.modal', () => {
      Utilities.setCookie(CampaignModal.PopupCookieKey, cacheKey, 7);
    }, false);

    // dispatch the event
    document.dispatchEvent(event);
  }
}

export default CampaignModal;