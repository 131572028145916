import Ecommerce from './ecommerce';

/**
 * Initializes Custom Filter Select Box
 */
class FilterSelect {
  static init(): void {
    const selectElement: NodeListOf<Element> = document.querySelectorAll('.js-filter-select');
    const radios: NodeListOf<Element> = document.querySelectorAll('.js-filter-select-input');

    if (!selectElement || selectElement.length === 0) {
      return;
    }

    // add event listeners
    radios.forEach((radio) => {
      radio.addEventListener('change', this.applyFilter, true);
    });

    this.applySelectedFiltersToCategoryLinks();
  }

  /**
   * Setup category links if filter is selected
   */
  static applySelectedFiltersToCategoryLinks(): void {
    const params = new URLSearchParams(window.location.search);
    const orientation = params.get('orientation');
    const color = params.get('color');

    if (orientation || color) {
      const categoriesListElement: HTMLUListElement = document.querySelector('.js-categories-list');
      const categoryLinks = categoriesListElement?.querySelectorAll('.m-categories__list-link');

      let filterSuffix = '';

      if (orientation && !color) {
        filterSuffix = `?orientation=${orientation}`; 
      }
      if (color && !orientation) {
        filterSuffix = `?color=${color}`;
      }
      if (color && orientation) {
        filterSuffix = `?orientation=${orientation}&color=${color}`;
      }
      
      categoryLinks?.forEach((link: HTMLLinkElement) => {
        link.href += filterSuffix;
      });
    }
  }

  /**
   * Apply filter value to location
   * @param event - change event
   */
  static applyFilter(event: Event): void {
    const input = event.srcElement as HTMLInputElement;

    // push gtm event
    Ecommerce.pushFilterSelectEvent(input);

    // reload with filter query param applied
    document.location.href = input.value;
  }
}

export default FilterSelect;
