import { DictionaryService } from './dictionary-service';
import Clipboard from './clipboard';
import Utilities from './utilities';
import Ecommerce from './ecommerce';
import VoyadoService from './voyado-service';

/**
 * The newsletter modal
 */
class NewsLetterModal {
  static ModalCookieName = 'PrintlerNewsletterModalViewed';
  static ErrorReason: string;
  static ShowError = false;
  /*
   * Render newsletter form
   */
  static init(): Promise<void> {
    const modal: HTMLElement = document.querySelector('#newsletterModal');

    if (!modal) {
      return;
    }

    const signupPageTemplateBodyCssClass = 'newslettersignuppage';
    const trigger: HTMLButtonElement = document.querySelector('.js-newsletter-modal-trigger');
    let showModal = !Utilities.getCookie(NewsLetterModal.ModalCookieName);
    const delay: number = +modal.getAttribute('data-delay');
    const showSiteWide = modal.getAttribute('data-sitewide') === 'true';
    const isStartPage = window.Printler.Current === window.Printler.Root;
    const isSignupPage = document.querySelector('body').classList.contains(signupPageTemplateBodyCssClass);

    // dont show modal if not sitewide and current page is not startpage
    if (!showSiteWide && !isStartPage) {
      showModal = false;
    }

    // dont show modal on signup page template
    if (isSignupPage) {
      showModal = false;
    }

    // fire click event on trigger if not a cookie is present and we have a delay set
    if (showModal && delay > 0) {
      // set initial timeout
      let timeoutHandle = setTimeout(() => {
        trigger.click();
      }, delay);

      // clear timeout
      document.querySelector('body').addEventListener('click', () => {
        clearTimeout(timeoutHandle);

        // re-init timeout if not modal has been shown
        if (!Utilities.getCookie(NewsLetterModal.ModalCookieName)) {
          timeoutHandle = setTimeout(() => {
            trigger.click();
          }, delay);
        }
      });
    }

    const stepTriggerBtn: HTMLButtonElement = modal.querySelector('.js-newsletter-step-btn');

    // modal shown event
    modal.addEventListener('shown.bs.modal', () => {
      Utilities.setCookie(NewsLetterModal.ModalCookieName, 'true', 365);

      const emailInputField = modal.querySelector('#emailInput');
      // trigger button click on enter keyup
      emailInputField.addEventListener('keyup', (e: KeyboardEvent) => {
        if (e.code === 'Enter') {
          stepTriggerBtn.click();
        }
      });

      stepTriggerBtn.addEventListener('click', () => {
        const formContainer: HTMLElement = document.querySelector('#newsletterModalForm');
        const emailInput: HTMLInputElement = formContainer.querySelector('#emailInput');
        const countrySelect: HTMLSelectElement = formContainer.querySelector('#countrySelect');
        const spinner: HTMLElement = modal.querySelector('.js-newsletter-modal-spinner');
        const validation: HTMLElement = modal.querySelector('.js-validation-error');
        const discountCodeSection = modal.querySelector('#newsletterSignupStep2');
        const signupSourceTag = formContainer.dataset.source ?? 'modal';
        // const customAudienceId = formContainer.dataset.audience;
        // const doubleOptIn = formContainer.dataset.doubleOptin;

        // validate inputs
        if (NewsLetterModal.validate(formContainer, emailInput, countrySelect)) {
          // hide initial content
          formContainer.classList.add('d-none');
          validation.classList.add('d-none');

          // show spinner 
          spinner.classList.remove('is-hidden');

          // reset error message
          NewsLetterModal.ErrorReason = 'An error ocurred when submitting the form. Please validate that your email was entered correct.';

          // call api and register subscriber
          VoyadoService.addVoyadoContactAsync(emailInput.value, countrySelect.value, window.Printler.StoreId, 'popup').then(result => {
            if (result) {
              setTimeout(() => {
                // populate discount code dynamically
                NewsLetterModal.populateDiscountCode(modal).then(() => {
                  spinner.classList.add('is-hidden');
                  discountCodeSection.classList.remove('d-none');

                  modal.addEventListener('copied', (e: CustomEvent) => {
                    e.stopPropagation();

                    const copyBtn = modal.querySelector('.js-discount-copy-xl'); // get the big copy btn

                    if (e.detail.done) {
                      copyBtn.classList.add('d-none');

                      const closeBtn = modal.querySelector('.js-close-modal-btn');
                      closeBtn.classList.remove('d-none');
                    }
                  });

                });
              }, 300);

              // on form submit push sign up event to GTM
              Ecommerce.pushNewsLetterSignupEvent(`Newsletter ${signupSourceTag}`);
            } else {
              Utilities.error(NewsLetterModal.ErrorReason);
              setTimeout(() => {
                spinner.classList.add('is-hidden');
                validation.innerHTML = NewsLetterModal.ErrorReason;
                validation.classList.remove('d-none');
                formContainer.classList.remove('d-none');
              }, 200);
            }
          });
        }
        else {
          Utilities.error('Missing fields.');
          validation.innerHTML = validation.getAttribute('data-invalid');
          validation.classList.remove('d-none');
        }
      });
    }, false);
  }

  /**
   * Validate fields
   * @param formContainer
   */
  static validate(formContainer: HTMLElement, emailInput: HTMLInputElement, countrySelect: HTMLSelectElement): boolean {
    if (emailInput.value && countrySelect.value && formContainer) {
      return true;
    }

    return false;
  }

  /**
   * Dynamically populate code via dictionary async
   * @param modal
   */
  static async populateDiscountCode(modal: HTMLElement): Promise<void> {
    const discountCodeInput: HTMLInputElement = modal.querySelector('.js-discount-code-input');
    const copyClipboardTexts: NodeListOf<HTMLElement> = modal.querySelectorAll('.js-discount-code-copy-text');
    const dcValue = await DictionaryService.translate('NewsLetterSignUpDiscountCodeValue', 'NEWSUBSCRIBER', 'SubscribeToNewsletter');

    discountCodeInput.value = dcValue;

    copyClipboardTexts.forEach(elm => {
      elm.setAttribute('data-copy-text', dcValue);
    });

    // re-init clipboard buttons
    Clipboard.init();
  }
}

export default NewsLetterModal;
