import { ApiEndpoints } from '../enums';
import { CartItem, CartItemAdd, CartItemAddonAdd, EstimatedPrice } from '../models';
import UrlService from './url-service';
import Utilities from './utilities';

class CartService {
  /**
   * Returns cart html
   */
  static async getCartHtml(): Promise<string> {
    const requestHeaders = new Headers();
    requestHeaders.append('pragma', 'no-cache');
    requestHeaders.append('cache-control', 'no-cache');

    const requestOptions = {
      method: 'GET',
      headers: requestHeaders,
    };

    const requestUrl = UrlService.getRenderUrl(ApiEndpoints.Cart);

    try {
      const response = await fetch(requestUrl, requestOptions);
      return await response.text();
    } catch (error) {
      if (error) {
        Utilities.log(error);
        return null;
      }
    }
  }

  /**
   * Get cart items as json
   */
  static async getCartItems(): Promise<CartItem[]> {
    const requestHeaders = new Headers();
    requestHeaders.append('pragma', 'no-cache');
    requestHeaders.append('cache-control', 'no-cache');

    const requestOptions = {
      method: 'GET',
      headers: requestHeaders,
    };

    const requestUrl = UrlService.getRenderUrl(ApiEndpoints.CartItems);

    try {
      const response = await fetch(requestUrl, requestOptions);
      return await response.json();
    } catch (error) {
      if (error) {
        // Utilities.log(error);
        return null;
      }
    }
  }

  /**
   * Add new product to cart
   * @param cartItems - object containing photoId, productId and addon id
   * @param options (optional) Optional extra request parameters, wallId is the current wall id if the price is requested for a wall planner page
   */
  static async addProducts(cartItems: CartItemAdd[], options?: { wallId?: number }): Promise<boolean> {
    if (!cartItems || !cartItems.length) {
      return false;
    }

    const jsonData = JSON.stringify({
      cartItems: cartItems.map(ci => {
        return {
          ...ci,
          addon: ci.addon === 0 ? null : ci.addon,
        } as CartItemAdd;
      }),
    });

    const blob = new Blob([jsonData], {
      type: 'application/json',
    });

    const requestOptions = {
      method: 'POST',
      body: blob,
    } as RequestInit;

    const requestUrl = UrlService.getRenderUrl(ApiEndpoints.CartItems, options);

    try {
      const response = await fetch(requestUrl, requestOptions);
      return response.ok;
    } catch (error) {
      if (error) {
        Utilities.log(error.message);
        return false;
      }
    }
  }

  /**
   * Add a new addon product to the cart
   * @param cartItem The addon to add
   * @returns A promise that resolves to true or false depending on success or not
   */
  static async addProductAddon(cartItem: CartItemAddonAdd): Promise<boolean> {
    if (cartItem === null) {
      return false;
    }

    const ci = {
      ...cartItem,
      addon: cartItem.addon === 0 ? null : cartItem.addon,
    };

    // add options
    const requestOptions = {
      method: 'POST',
    };

    const requestUrl = UrlService.getRenderUrl(ApiEndpoints.CartAddonItem, {
      parentId: ci.parentId,
      addon: ci.addon,
    });

    try {
      const response = await fetch(requestUrl, requestOptions);
      return response.ok;
    } catch (error) {
      if (error) {
        Utilities.log(error.message);
        return false;
      }
    }
  }

  /**
   * Add new product to cart
   * @param cartItem - object containing photoId, productId and addon id
   * @returns A promise that resolves to true or false depending on success or not
   */
  static async addProduct(cartItem: CartItemAdd): Promise<boolean> {
    if (cartItem === null) {
      return false;
    }

    const ci = {
      ...cartItem,
      addon: cartItem.addon === 0 ? null : cartItem.addon,
    };

    // add options
    const options = JSON.stringify(ci.options);
    const formData = new FormData();

    formData.append('data', options);

    const requestOptions = {
      method: 'POST',
      body: formData,
    };

    const requestUrl = UrlService.getRenderUrl(ApiEndpoints.CartItem, {
      photo: ci.photoId,
      product: ci.productId,
      addon: ci.addon,
      addons: ci.addons,
    });

    try {
      const response = await fetch(requestUrl, requestOptions);
      return response.ok;
    } catch (error) {
      if (error) {
        Utilities.log(error.message);
        return false;
      }
    }
  }

  /**
   * Delete product/addon from cart
   * @param id - id of product or addon
   */
  static async deleteProduct(id: number): Promise<boolean> {
    const requestOptions = {
      method: 'DELETE',
    };

    const requestBaseUrl = UrlService.getRenderUrl(ApiEndpoints.CartItem, {
      id: id,
    });

    try {
      const response = await fetch(requestBaseUrl, requestOptions);
      return response.ok;
    } catch (error) {
      if (error) {
        Utilities.log(error.message);
        return false;
      }
    }
  }

  /**
   * Clear cart
   */
  static async clearCart(): Promise<boolean> {
    const requestOptions = {
      method: 'GET',
    };

    const requestBaseUrl = UrlService.getRenderUrl(ApiEndpoints.CartClear);

    try {
      const response = await fetch(requestBaseUrl, requestOptions);
      return response.ok;
    } catch (error) {
      if (error) {
        Utilities.log(error.message);
        return false;
      }
    }
  }

  /**
   * Get product selector simple html
   * @param cartItemId - cart item id
   * @param addonId - nullable addon product id
   */
  static async getProductSelectorHtml(cartItemId: number, addonId?: number): Promise<string> {
    const requestHeaders = new Headers();
    requestHeaders.append('pragma', 'no-cache');
    requestHeaders.append('cache-control', 'no-cache');

    const requestOptions = {
      method: 'GET',
      headers: requestHeaders,
    };

    const requestUrl = UrlService.getRenderUrl(ApiEndpoints.ProductSelectorSimple, {
      cartItemId: cartItemId,
      addonId: addonId,
    });

    try {
      const response = await fetch(requestUrl, requestOptions);
      return await response.text();
    } catch (error) {
      if (error) {
        Utilities.log(error);
        return null;
      }
    }
  }

  /**
   * Gets an estimated price for a given list of cart items
   * @param cartItems The cart items to get the price for
   * @param options (optional) Optional extra request parameters, wallId is the current wall id if the price is requested for a wall planner page or gallery wall page
   */
  static async getEstimatedPrice(cartItems: CartItemAdd[], options?: { wallId?: number }): Promise<EstimatedPrice> {
    const jsonData = JSON.stringify({
      cartItems: cartItems,
    });

    const blob = new Blob([jsonData], {
      type: 'application/json',
    });

    const requestOptions = {
      method: 'POST',
      body: blob,
    } as RequestInit;

    const requestUrl = UrlService.getRenderUrl(ApiEndpoints.EstimatedCartPrice, options);

    try {
      const response = await fetch(requestUrl, requestOptions);
      return await response.json();
    } catch (error) {
      if (error) {
        Utilities.log(error.message);
        return null;
      }
    }
    }
}

export default CartService;
