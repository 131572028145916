import { ApiEndpoints } from '../enums';
import UrlService from './url-service';
import Utilities from './utilities';

/**
 * Initializes Contact form logic
 */
class ContactForm {
  static init(): void {
    const contactForm: HTMLFormElement = document.querySelector('#contactForm');

    if (!contactForm || contactForm.length === 0) {
      return;
    }

    const successContainer: HTMLElement = document.querySelector('.js-valid-form-message');
    const errorContainer: HTMLElement = document.querySelector('.js-invalid-form-message');
    const submitButton: HTMLElement = document.querySelector('.js-contact-form-submit');

    // add submit event listener
    contactForm.addEventListener(
      'submit',
      (e: Event) => {
        successContainer.classList.add('d-none');
        errorContainer.classList.add('d-none');
        submitButton.setAttribute('disabled', 'disabled');

        const result = this.handleFormSubmit(e, contactForm);

        if (result) {
          successContainer.classList.remove('d-none');
        } else {
          errorContainer.classList.remove('d-none');
        }

        setTimeout(function () {
          submitButton.removeAttribute('disabled');
        }, 500);
      },
      true,
    );
  }

  /**
   * Form submit handler
   * @param event - submit event
   */
  static handleFormSubmit(event: Event, form: HTMLFormElement): boolean {
    event.preventDefault();

    // validate form before send
    if (ContactForm.validate(form)) {
      const data = new FormData(form); // ContactForm.serialize(form);

      const requestOptions = {
        method: 'POST',
        body: data,
      };

      const requestUrl = UrlService.getRenderUrl(ApiEndpoints.Contact);

      try {
        const response = fetch(requestUrl, requestOptions);
        if (response) {
          return true;
        }
      } catch (error) {
        Utilities.log(error);
        return null;
      }
    } else {
      return false;
    }
  }

  /**
   * Validate form
   * @param form
   */
  static validate(form: HTMLFormElement): boolean {
    let valid = true;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      valid = false;
    }
    form.classList.add('was-validated');

    return valid;
  }
}

export default ContactForm;
