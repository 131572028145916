import { EntityType } from '../enums';
import { CartItemAdd, CartItemAddonAdd, EcommerceProduct, Photo } from '../models';
import { PhotoService } from './photo-service';
import Utilities from './utilities';
import VoyadoService from './voyado-service';
import CartService from './cart-service';

/**
 * Ecommerce logic - via datalayer
 */
class Ecommerce {
  static ProductCardSelector = '.js-datalayer-product-card';
  static ProductLinkSelector = '.js-datalayer-product-link';
  static ProductDetailsSelector = '.js-datalayer-details';
  static CheckoutSelector = '.js-checkout-page';
  static CartItemSelector = '.js-gtm-cartitem';

  /**
   * Initialize ecommerce events. Init function is not called until Cookiebot consent has been given and loaded.
   */
  static init(): void {
    // setup product list click events
    Ecommerce.setupProductListClick();

    // Defer work that isn't user-visible to a separate task:
    setTimeout(() => {
      // setup product impressions
      Ecommerce.setupProductImpressions();
      // checkout
      Ecommerce.pushCheckoutPageImpression();
    }, 0);
  }

  /**
   * Check cookie consent in Cookiebot object
   * @returns a boolean indication if consent has been given
   */
  static hasCookieConsent(consent = 'statistics'): boolean {
    let givenConsent = window.Cookiebot.consent.statistics;

    if (consent === 'marketing') {
      givenConsent = window.Cookiebot.consent.marketing;
    }
    return givenConsent;
  }

  /**
   * Setup product impressions
   */
  static setupProductImpressions(): void {
    // Product Details Impresssions
    const productDetailsElement: HTMLElement = document.querySelector(Ecommerce.ProductDetailsSelector);

    if (productDetailsElement) {
      Ecommerce.pushProductDetailsImpression(productDetailsElement);
    }

    // Product List Impressions
    // If the list is populated async, make sure the list in fully rendered when pushing events (see posters.ts)
    const productLists: NodeListOf<HTMLElement> = document.querySelectorAll('[data-gtm-list]:not(.js-posters-container)');

    if (productLists && productLists.length > 0) {
      // loop all lists and setup product impressions
      productLists.forEach((list: HTMLElement) => {
        Ecommerce.pushProductListImpression(list);
      });
    }
  }

  /**
   * Setup product list click event
   */
  static setupProductListClick(): void {
    const productListLinks: NodeListOf<HTMLElement> = document.querySelectorAll(Ecommerce.ProductLinkSelector);

    // product links in lists - click event
    productListLinks.forEach((productLink: HTMLElement) => {
      productLink.addEventListener('click', (e: Event) => {
        Ecommerce.pushProductListClick(e.currentTarget as HTMLElement);
      });
    });
  }

  /**
   * Push product in List click event
   * Call this function when a user clicks on a product link. This function uses the event
   * callback datalayer variable to handle navigation after the ecommerce data has been sent
   * to Google Analytics.
   * productObj An object representing a product.
   * @param productLink - the product element to add to datalayer
   */
  static pushProductListClick(productLink: HTMLElement): void {
    if (Ecommerce.hasCookieConsent()) {
      const listElement: HTMLElement = productLink?.closest('[data-gtm-list]');
      const productList: NodeListOf<Element> = listElement?.querySelectorAll('[data-gtm-id]');
      const product: HTMLElement = productLink?.closest('[data-gtm-id]');
      const list = listElement?.dataset?.gtmList;
      const productCategory = product?.dataset?.gtmCategory;
      const productUrl = productLink?.getAttribute('href');

      const prod: EcommerceProduct = {
        id: `${product?.dataset?.gtmId}`,
        name: product?.dataset?.gtmName,
        price: product?.dataset?.gtmPrice,
        brand: product?.dataset?.gtmBrand,
        category: productCategory,
        position: Array.from(productList).indexOf(product),
        list: list,
      };

      // push to datalayer
      window.dataLayer.push({ ecommerce: null });  // clear the previous ecommerce object.
      window.dataLayer.push({
        'event': 'productClick',
        'ecommerce': {
          'currencyCode': window.Printler.Currency,
          'click': {
            'actionField': { 'list': list },
            'products': [prod]
          }
        },
        'eventCallback': () => {
          document.location.href = productUrl
        }
      });
    }
  }

  /**
   * Push product list impressions
   * If the list is populated async, make sure the list in fully rendered when pushing events (see posters.ts)
   * Take only first 6 products
   * Measures product impressions and also tracks a standard pageview for the tag configuration.
   * Product impressions are sent by pushing an impressions object containing one or more impressionFieldObjects.
   * @param list - the list HTMLElement
   */
  static pushProductListImpression(list: HTMLElement): void {
    if (Ecommerce.hasCookieConsent()) {
      const productCardItems: NodeListOf<HTMLElement> = list?.querySelectorAll(Ecommerce.ProductCardSelector);
      const products: EcommerceProduct[] = [];
      const numberOfProducts = 6;
      let position = 1;

      if (productCardItems.length === 0) {
        // no product card elements in list (rendered async, see pushProductImpressionsWhenInViewport below)
        return;
      }

      // push ecommerce products to array
      productCardItems.forEach((card: HTMLElement) => {
        if (position > numberOfProducts)
          return;

        const prod: EcommerceProduct = {
          id: `${card.dataset.gtmId}`,
          name: card.dataset.gtmName,
          price: card.dataset.gtmPrice,
          brand: card.dataset.gtmBrand,
          category: card.dataset.gtmCategory,
          position: position,
          list: list.dataset.gtmList
        };

        products.push(prod);

        position++;
      });

      window.dataLayer.push({ ecommerce: null });  // clear the previous ecommerce object.
      window.dataLayer.push({
        'event': 'productImpression',
        'ecommerce': {
          'currencyCode': window.Printler.Currency,
          'impressions': products,
        }
      });
    }
  }

  /**
   * Push product details impression
   * Will push on PhotoPage visit
   * @param product - The product HTMLElement
   */
  static pushProductDetailsImpression(product: HTMLElement): void {
    if (Ecommerce.hasCookieConsent()) {
      const ecommercePrd: EcommerceProduct = {
        id: `${product.dataset.gtmProductId}`,
        name: product.dataset.gtmProductName,
        price: product.dataset.gtmProductPrice,
        brand: product.dataset.gtmProductBrand,
        category: product.dataset.gtmProductCategory,
        variant: product.dataset.gtmProductVariant
      };

      // datalayer push event
      window.dataLayer.push({ ecommerce: null });  // clear the previous ecommerce object.
      window.dataLayer.push({
        'event': 'productDetail',
        'ecommerce': {
          'currencyCode': window.Printler.Currency,
          'detail': {
            'products': [ecommercePrd]
          }
        }
      });
    }

    // Voyado - push product view
    window.va('productview', {
      'categoryName': product.dataset.gtmProductCategory,
      'itemId': `${product.dataset.gtmProductId}`,
      'contactId': `${Utilities.getCookie('_val') ?? ''}`,
    });
  }

  /**
   * If list (container) is in viewport this function will push the impressions event 
   * @param container - The posters container with asynchronous loaded posters
   */
  static pushProductImpressionsWhenInViewport(container: HTMLElement): void {
    const listEventsPushed: string[] = [];

    const debouncedScroll = Utilities.debounce(() => {
      if (Utilities.isScrolledIntoView(container)) {

        // if not events already fired
        if (!listEventsPushed.includes(container.dataset.gtmList)) {
          Ecommerce.pushProductListImpression(container);
          listEventsPushed.push(container.dataset.gtmList)
        }
      }
    }, 50);
    window.addEventListener('scroll', debouncedScroll);
  }

  /**
  * A function to handle a click on a checkout button. This function uses the eventCallback
  * data layer variable to handle navigation after the ecommerce data has been sent to Google Analytics.
  */
  static pushCheckoutPageImpression(): void {
    const checkout: HTMLElement = document.querySelector(Ecommerce.CheckoutSelector);

    if (!checkout) {
      return;
    }

    const paymentProvider = checkout?.dataset?.paymentProvider;

    if (Ecommerce.hasCookieConsent()) {
      const orderLines: NodeListOf<HTMLElement> = checkout.querySelectorAll('.js-datalayer-orderline');
      const productArray: EcommerceProduct[] = [];
      let index = 1;

      orderLines.forEach(line => {
        const prd: EcommerceProduct = {
          id: line.dataset.gtmOrderlineId,
          name: line.dataset.gtmOrderlineName,
          price: line.dataset.gtmOrderlinePrice,
          brand: line.dataset.gtmOrderlineBrand,
          category: line.dataset.gtmOrderlineCategory,
          position: index,
          quantity: 1,
        };
        index++;

        productArray.push(prd);
      });

      // Step 1: push initial checkout impression

      window.dataLayer.push({ ecommerce: null });  // clear the previous ecommerce object.
      window.dataLayer.push({
        'event': 'checkout',
        'ecommerce': {
          'currencyCode': window.Printler.Currency,
          'checkout': {
            'actionField': { 'step': 1 },
            'products': productArray
          }
        }
      });

      // Step 2: push selected payment method

      window.dataLayer.push({ ecommerce: null });  // clear the previous ecommerce object.
      window.dataLayer.push({
        'event': 'checkoutOption',
        'ecommerce': {
          'checkout_option': {
            'actionField': { 'step': 2, 'option': `${paymentProvider}` }
          }
        }
      });

      // Step 3: listen for change event on shipping options in KCO when KCO finished loading

      /* eslint-disable */
      if (paymentProvider?.toLowerCase() == 'klarnav3') { 
        try {
          window._klarnaCheckout((api: any) => {
            api.on({
              'load': (data: any) => {
                Ecommerce.setupSelectedShippingpOption(data.shipping_option_change.id);
              }
            });
          });
        } catch (e) { console.log('Klarna shipping selection', e) }
      }
      /* eslint-enable */
    }
  }

  /**
   * This function will send information to GTM about options selected by the user during the checkout.
   * GTM will be configured to relay this information to Google Analytics.
   * @param fallbackShippingOptionId - id of the fallback shipping option returned by Klarna
   */
  static setupSelectedShippingpOption(fallbackShippingOptionId: number): void {
    if (Ecommerce.hasCookieConsent()) {
      let defaultShippingOptionId = ''; // The default selected shipping option

      /* eslint-disable */
      window._klarnaCheckout((api: any) => {
        api.on({
          'shipping_option_change': (data: any) => {
            // set default shipping option if not set
            if (data.id != fallbackShippingOptionId && defaultShippingOptionId == '') {
              defaultShippingOptionId = data.id;
            }

            // user changed from default shipping option
            if (data.id != defaultShippingOptionId && data.id != fallbackShippingOptionId) {
              // push selected shipping option
              window.dataLayer.push({ ecommerce: null });  // clear the previous ecommerce object.
              window.dataLayer.push({
                'event': 'checkoutOption',
                'ecommerce': {
                  'checkout_option': {
                    'actionField': { 'step': 3, 'option': data.name }
                  }
                }
              });
            }
          }
        });
      });
      /* eslint-enable */
    }
  }

  /**
   * This function will send information to Google Tag Manager (GTM) about products added to cart.
   * GTM will be configured to relay this information to Google Analytics (GA).
   * The function call comes from cart.ts - addtocart
   * @param cartItem
   * @param isAddon
   */
  static pushAddToCartEvent(cartItem: CartItemAdd): void {
    const cartItems: NodeListOf<HTMLElement> = document.querySelectorAll(Ecommerce.CartItemSelector);
    const cartItemElement: HTMLElement = Array.from(cartItems).find(
      node => node.dataset.gtmCartitemProductId === cartItem.productId.toString() &&
        node.dataset.gtmCartitemId == cartItem.photoId.toString());

    if (Ecommerce.hasCookieConsent()) {
      if (cartItemElement) {
        window.dataLayer.push({ ecommerce: null });  // clear the previous ecommerce object.
        window.dataLayer.push({
          'event': 'addToCart',
          'ecommerce': {
            'currencyCode': window.Printler.Currency,
            'add': {
              'products': [Ecommerce.mapCartitemToEcommerceProduct(cartItemElement)]
            }
          }
        });
      }
    }

    // Voyado - update cart
    VoyadoService.updateCart();
  }

  /**
   * This function will send information to Google Tag Manager (GTM) about products removed from cart. 
   * GTM will be configured to relay this information to Google Analytics (GA).
   * The function call comes from cart.ts - deletefromcart
   * @param cartItemId - string representation of the cart item id
   */
  static pushRemoveFromCartEvent(cartItemId: string): void {
    const cartItems: NodeListOf<HTMLElement> = document.querySelectorAll(Ecommerce.CartItemSelector);
    const cartItemElement: HTMLElement = Array.from(cartItems).find(node => node.dataset.gtmCartitemid === cartItemId);

    if (Ecommerce.hasCookieConsent()) {
      if (cartItemElement) {
        window.dataLayer.push({ ecommerce: null });  // clear the previous ecommerce object.
        window.dataLayer.push({
          'event': 'removeFromCart',
          'ecommerce': {
            'currencyCode': window.Printler.Currency,
            'add': {
              'products': [Ecommerce.mapCartitemToEcommerceProduct(cartItemElement)]
            }
          }
        });
      }

      // Voyado - update cart.
      VoyadoService.updateCart();
    }
  }

  /**
   * Push add to cart event for addon
   * @param cartItemAddon
   */
  static pushAddonAddToCartEvent(cartItemAddon: CartItemAddonAdd): void {
    const cartItems: NodeListOf<HTMLElement> = document.querySelectorAll(Ecommerce.CartItemSelector);
    const cartItemElement: HTMLElement = Array.from(cartItems).find(node => node.dataset.gtmCartitemId == cartItemAddon.addon?.toString());

    if (Ecommerce.hasCookieConsent()) {
      if (cartItemElement) {
        window.dataLayer.push({ ecommerce: null });  // clear the previous ecommerce object.
        window.dataLayer.push({
          'event': 'addToCart',
          'ecommerce': {
            'currencyCode': window.Printler.Currency,
            'add': {
              'products': [Ecommerce.mapCartitemToEcommerceProduct(cartItemElement)]
            }
          }
        });
      }
    }

    // Voyado - update cart
    VoyadoService.updateCart();
  }

  /**
   * Push add to cart event for upsells in cart edit product
   * @param addonId
   * @param addonType
   * @param addonFormattedPrice - works with both integers and formatted price ex. '349 kr'
   */
  static pushUpsellsAddonAddToCartEvent(addonId: number, addonType: string, addonFormattedPrice: string): void {
    if (Ecommerce.hasCookieConsent()) {
      const addonPrice = addonFormattedPrice?.substr(0, addonFormattedPrice?.indexOf(' '));

      window.dataLayer.push({ ecommerce: null });  // clear the previous ecommerce object.
      window.dataLayer.push({
        'event': 'addToCart',
        'eventLabel': 'Cart upsells',
        'ecommerce': {
          'currencyCode': window.Printler.Currency,
          'add': {
            'products': [{
              id: `PRODUCT-${addonId}`,
              price: `${addonPrice}.00`,
              brand: 'Printler',
              category: 'Addons',
              quantity: 1,
            }]
          }
        }
      });

      // Voyado?
    }
  }

  /**
   * Map a cart item node element to a EcommerceProduct object
   * @param cartItemElement
   * returns an ecommerce product object
   */
  static mapCartitemToEcommerceProduct(cartItemElement: HTMLElement): EcommerceProduct {
    const prd: EcommerceProduct = {
      id: cartItemElement.dataset.gtmCartitemSku,
      name: cartItemElement.dataset.gtmCartitemName,
      price: cartItemElement.dataset.gtmCartitemPrice,
      brand: cartItemElement.dataset.gtmCartitemBrand,
      category: cartItemElement.dataset.gtmCartitemCategory,
      quantity: 1,
    };

    return prd;
  }

  /**
   * Add to favourites event. 
   * @param entityType - artist or poster
   * @param id - entity id
   */
  static pushAddToFavourites(entityType: EntityType, id: number): void {
    if (Ecommerce.hasCookieConsent()) {
      // fetch poster data
      if (entityType === EntityType.Poster) {
        PhotoService.getByIds([id]).then((result: Photo[]) => {
          // push gtm event
          window.dataLayer.push({
            'event': 'Favourites',
            'eventCategory': 'Favourites',
            'eventAction': 'Poster',
            'eventLabel': result[0].NativeTitle,
          });
        });
      } else {
        const selector = `[data-gtm-member-id="${id}"]`;
        const favouriteArtistsContainer: HTMLElement = document.querySelector('.js-favourites-container-artists');
        const artist: HTMLElement = favouriteArtistsContainer?.querySelector(selector);

        // push gtm event
        window.dataLayer.push({
          'event': 'Favourites',
          'eventCategory': 'Favourites',
          'eventAction': 'Artist',
          'eventLabel': artist?.getAttribute('data-gtm-member-name') ?? `${id}`,
        });
      }
    }
  }

  /**
   * Triggered on basket modal shown
   */
  static pushViewCartEvent(): void {
    try {
      CartService.getCartItems().then(result => {
        const products: EcommerceProduct[] = [];

        result.forEach(item => {
          const elm: HTMLElement = document.querySelector(`.js-gtm-cartitem[data-gtm-cartitemid="${item.Id}"]`);

          // sub-addons = undefined - only add first level cart items
          if (elm != undefined) {
            const prd: EcommerceProduct = {
              id: elm?.getAttribute('data-gtm-cartitem-sku'),
              name: elm?.getAttribute('data-gtm-cartitem-name'),
              price: elm?.getAttribute('data-gtm-cartitem-price'),
              brand: elm?.getAttribute('data-gtm-cartitem-brand'),
              category: elm?.getAttribute('data-gtm-cartitem-category'),
              quantity: 1,
            };

            products.push(prd);
          }
        });

        if (Ecommerce.hasCookieConsent()) {
          window.dataLayer.push({
            'event': 'View',
            'eventCategory': 'View',
            'eventAction': 'Basket',
            'items': products
          });
        }
      });
    }
    catch (e) {
      Utilities.error('Error fetching cart items');
    }
  }

  /**
   * Triggered on framed art print modal view
   */
  static pushViewFramedArtPrintEvent(): void {
    if (Ecommerce.hasCookieConsent()) {
      window.dataLayer.push({
        'event': 'View',
        'eventCategory': 'View',
        'eventAction': 'Framed Art Print',
      });
    }
  }

  /**
   * Triggered on filter choice
   * @param category - filter type, e.g 'Color'
   * @param value - filter label, e g 'Black'
   */
  static pushFilterSelectEvent(input: HTMLInputElement): void {
    if (!input) {
      return;
    }

    if (Ecommerce.hasCookieConsent()) {
      let label = input.value.split('=')[1];

      // if value includes &, more than 1 filter is applied, get last filter.
      if (input?.value.includes('&')) {
        label = input.value.split('=').pop();
      }

      window.dataLayer.push({
        'event': 'Filter',
        'eventCategory': 'Filter',
        'eventAction': input.name,
        'eventLabel': label,
      });
    }
  }

  /**
   * Triggered on email signups
   * @param action - optional action parameter
   */
  static pushNewsLetterSignupEvent(action = 'Email newsletter'): void {
    if (Ecommerce.hasCookieConsent()) {
      window.dataLayer.push({
        'event': 'Sign up',
        'eventCategory': 'Sign up',
        'eventAction': action,
      });
    }
  }

  /**
   * Triggered on artist signup/registration
   */
  static pushArtistSignupEvent(): void {
    if (Ecommerce.hasCookieConsent()) {
      window.dataLayer.push({
        'event': 'Sign up',
        'eventCategory': 'Sign up',
        'eventAction': 'New artist',
      });
    }
  }

  /**
   * NOT ACTIVE - find internal promotions
   * Trigger the event when the promotion comes into view.
   */
  static setupInternalPromotionImpressions(): void {
    const promos: NodeListOf<HTMLElement> = document.querySelectorAll('[data-gtm-internal-promotion-id]');
    console.log(promos);
  }

  /**
   * NOT ACTIVE - Push the event about impressions of internal promotions.
   * @param internalImpressionContainer
   */
  static pushInternalPromotionImpression(internalImpressionContainer: HTMLElement): void {
    const id = internalImpressionContainer.getAttribute('data-gtm-internal-promotion-id');
    const name = internalImpressionContainer.getAttribute('data-gtm-internal-promotion-name');
    const creative = internalImpressionContainer.getAttribute('data-gtm-internal-promotion-creative');
    const position = internalImpressionContainer.getAttribute('data-gtm-internal-promotion-position');

    console.log({ id, name, creative, position });

    /*
    window.dataLayer.push({
      'event': 'promotionImpression',
      'ecommerce': {
        'promoView': {
          'promotions': [
            {
              'id': 'Printlers artister',
              'name': 'Linnea Frank',
              'creative': 'Printler x Linnea Frank',
              'position': 'Startpage 1'
            }]
        }
      }
    });*/
  }

  /**
   * Share event - copy gallery url or download image in mockup
   * @param event
   * @param action
   * @param artistId
   * @param label
   */
  static pushArtistShareEvent(event = 'shareClick', action = 'Click', label: string, artistId: string, artworkId?: string, templateId?: string): void {
    window.dataLayer.push({
      'event': `${event}`,
      'eventCategory': 'Artist Share Interaction',
      'eventAction': `${action}`,
      'eventLabel': `${label}`,
      'artistId': `${artistId}`,
      'artworkId': `${artworkId}`,
      'templateId': `${templateId}`
    });
  }
}

export default Ecommerce;
