import { ApiEndpoints } from './../enums';
import Carousel from './../modules/carousel';
import Favourites from './../modules/favourites';
import Ecommerce from './ecommerce';

/**
 * Posters - fetched async from endpoint in data attribute
 */
class Posters {
  static init(): void {
    Favourites.init(); // needed for posters not rendered by ajax

    const postersContainers: NodeListOf<HTMLElement> = document.querySelectorAll('.js-posters-container');

    if (!postersContainers || postersContainers.length === 0) {
      this.addFrames(document.querySelector('#postersGrid')); // needed for posters not rendered by ajax
      return;
    }

    postersContainers.forEach((container: HTMLElement) => {
      this.fetchPosters(container);
    });
  }

  /**
   * Fetch posters
   * @param container - HTMLElement where to append posters
   */
  static fetchPosters(container: HTMLElement): void {
    const endpoint: string = container.dataset.endpoint.toLowerCase();
    const photoId: string = container.dataset.photoId;
    const referrer: string = container.dataset.referrer;
    const maxResult: string = container.dataset.maxResult;
    const cacheKey: string = container.dataset.cachekey;
    const memberId = container.dataset.memberId;
    const query: string = container.dataset.query;

    const categories = container.dataset.categories;
    const orientation = container.dataset.orientation;
    const language = container.dataset.language;

    const color = container.dataset.primaryColor;
    const order = container.dataset.order;
    const noResult = container.dataset.noResult;

    const isMobile = window.innerWidth < 768;
    const mobileMaxResult = 6;

    const baseUrl = '/umbraco/printler/photos/';
    // TODO: perhaps use UrlService instead
    let fetchUrl = `${baseUrl}${endpoint}?referrer=${referrer}&locale=${window.Printler.Locale}`;

    if (endpoint === 'byindex') {
      fetchUrl = `${baseUrl}search?referrer=${referrer}&locale=${window.Printler.Locale}`;
    }

    // add params to fetch url based on endpoint
    switch (endpoint) {
      case ApiEndpoints.ByOrderHistory:
        fetchUrl += `&photoid=${photoId}&cacheKey=${cacheKey}`;
        container.classList.add('is-loading');
        break;
      case ApiEndpoints.ByMember:
        if (container.dataset.fullListing == 'true') {
          fetchUrl += `&memberid=${memberId}&displayInSingleCols=false&ignoreIds=${photoId}&noResult=${container.dataset.noResult.trim()}&cacheKey=${cacheKey}`;
          fetchUrl += `&skip=${+container.dataset.skip.trim()}&take=${+container.dataset.take.trim()}`;
          orientation?.trim().length !== 0 ? fetchUrl += `&orientation=${orientation?.trim()}` : null; // orientation
          order?.trim().length !== 0 ? fetchUrl += `&order=${order.trim()}` : 'staffpick'; // sort order
        }
        else {
          fetchUrl += `&memberid=${memberId}&take=4&displayInSingleCols=true&ignoreIds=${photoId}&cacheKey=${cacheKey}`;
        }
        container.classList.add('is-loading');
        break;
      case ApiEndpoints.ByCompositionPageReference:
        fetchUrl += `&maxResult=${isMobile ? mobileMaxResult : maxResult}&cacheKey=${cacheKey}_${isMobile}`;
        container.classList.add('is-loading');
        break;
      case ApiEndpoints.ByFeaturedIdsFromDb:
        fetchUrl += `&maxResult=${maxResult}&cacheKey=${cacheKey}`;
        container.classList.add('is-loading');
        break;
      case ApiEndpoints.BySimilar:
        fetchUrl += `&photoId=${photoId}&maxResult=${maxResult}&cacheKey=${cacheKey}`;
        container.classList.add('is-loading');
        break;
      case 'byindex': // search
        fetchUrl += `&q=${query}&maxResult=${maxResult}&cacheKey=${cacheKey}&order=score&noResult=${noResult?.trim()}`;
        fetchUrl += `&skip=${+container.dataset.skip.trim()}&take=${+container.dataset.take.trim()}`;
        break;
      case ApiEndpoints.BySearch: // searchaiasync
        fetchUrl += `&text=${query}&categories=${categories}&orientation=${orientation}&language=${language}&maxResult=${maxResult}&cacheKey=${cacheKey}`;
        fetchUrl += `&skip=${+container.dataset.skip.trim()}&take=${+container.dataset.take.trim()}`;
        break;
      case ApiEndpoints.CompositionPageList:
        fetchUrl += `&skip=${+container.dataset.skip.trim()}` +
                    `&take=${+container.dataset.take.trim()}` +
                    `&noResult=${noResult?.trim()}` +
                    `&cacheKey=${cacheKey?.trim()}`;
        // add type only if set
        orientation.trim().length !== 0 ? fetchUrl += `&orientation=${orientation.trim()}` : null; // orientation
        color?.trim().length !== 0 ? fetchUrl += `&primaryColor=${color?.trim()}` : null; // color
        order?.trim().length !== 0 ? fetchUrl += `&order=${order?.trim()}` : 'score'; // sort order
        // display grid loader
        container.classList.add('is-loading');
        break;
    }

    // make request
    fetch(fetchUrl)
      .then(response => {
        if (response.ok) {
            return response.text();
        } else {
          container.style.display = 'none';
          throw new Error(`Error fetching posters. ${response.status}`);
        }
      })
      .then(result => {
        // if empty result hide section (only hide featured sections)
        if (result === '' && endpoint !== ApiEndpoints.CompositionPageList) {
          console.log('Empty result for endpoint ', endpoint);
          const section: HTMLElement = container.closest('.o-section');
          section.style.display = 'none';
          return;
        }

        // hide loader
        container.classList.remove('is-loading');

        // append result
        container.innerHTML = result;
        
        // init carousels
        Carousel.initProductCardSlider();
        Carousel.initFeaturedContentSlideShow();

        // init all BSN components (collapse)
        window.BSN.initCallback();

        // add frames
        this.addFrames(container);
        
        // when list is loaded push and comes into viewport Product list Impressions
        Ecommerce.pushProductImpressionsWhenInViewport(container);
      });
  }

  /**
   * Add frames to posters in grid
   * */
  static addFrames(container: HTMLElement): void {
    if (!window.Printler.DisplayFramesInGrid === true) {
      return;
    }

    if (!container) {
      return;
    }
    
    const posters: NodeListOf<HTMLElement> = container.querySelectorAll('.js-product-card-frame');
    let index = 1;
    
    posters.forEach((poster: HTMLElement) => {
      // we create a random number between 0 and 100
      const randomValue = Math.random() * 100;

      // we add a frame 40%(?) of the time and never next to another frame
      if (randomValue < 40 && index % 3 != 0) {
        poster.classList.remove('m-frame--hide');

        // array of frame colors. include "hide" to allow to display motifs w/o frame but with margin
        const frameVariants = ['black', 'white', 'oak', 'walnut', 'rokoko']; // 'hide'
        const randomColor = frameVariants[Math.floor(Math.random() * frameVariants.length)];
        const randomFrameClass = `m-frame--${randomColor}`;

        // add random frame
        poster.classList.add(randomFrameClass);

        // append addon to poster link
        /*
        if (randomColor != 'hide') {
          const posterLinkElement: HTMLElement = poster?.closest('a');
          const href = posterLinkElement.getAttribute('href');
          posterLinkElement.setAttribute('href', `${href}?frame=${randomColor}`);
        }
        */

        // add margin on a few
        if (randomValue < 2) {
          poster.classList.add('has-margin');
        } 
      }
      
      index++;
    });
  }
}

export default Posters;
