import { ApiEndpoints } from '../enums';
import { FacebookEvent, FacebookEventUserData } from '../models/facebook-event.interface';
import UrlService from './url-service';
import Utilities from './utilities';

/**
 * The Facebook Conversion API service
 */
class FacebookConversionService {

  static init(): void {
    // listen for conversion event
    window.addEventListener('FbConversionEvent', (e: CustomEvent) => {
      this.pushEventAsync({
        id: e.detail.id,
        name: e.detail.name,
        sourceUrl: window.location.href,
        customData: e.detail.customData,
        userData: e.detail.userData,
      });
    });
  }

  /**
   * Push Facebook Events Async
   * @param fbEvent
   */
  static async pushEventAsync(fbEvent: FacebookEvent): Promise<void> {
    // do nothing if not activated
    if (!window.Printler.CapiActive) {
      return;
    }

    /* eslint-disable @typescript-eslint/camelcase */
    const userData: FacebookEventUserData = {
      client_user_agent: navigator.userAgent,
      country: window.Printler.CountryHash,
    };

    // add email if set
    if (fbEvent?.userData?.em !== '') {
      userData.em = fbEvent?.userData?.em;
    }

    const bodyData = JSON.stringify({'data': {
      eventName: fbEvent.name,
      eventTime: Math.floor(Date.now() / 1000), // currently overwritten by server
      eventId: `${fbEvent.id}`,
      eventSourceUrl: fbEvent.sourceUrl,
      actionSource: 'website',
      userData: userData,
      customData: fbEvent.customData
    }});
    /* eslint-enable @typescript-eslint/camelcase */

    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');

    const requestOptions = {
      headers: requestHeaders,
      method: 'POST',
      body: bodyData,
    };
    
    const requestUrl = UrlService.getRenderUrl(ApiEndpoints.FacebookConversionApiEndpoint);

    await fetch(requestUrl, requestOptions).then((response) => {
      if (response.ok) {
        return response.ok;
      }
    }).catch((error) => {
      Utilities.log(error);
    });
  }

  /**
   * Returns a generated unique event id
   */
  static getUniqueEventId(): number {
    return Math.floor(Math.random() * Date.now())
  }
}

export default FacebookConversionService;
